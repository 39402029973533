import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import SideMenu from "../sidemenu";
import "./menu.scss";
import { MultiSelect } from "react-multi-select-component";
import { SidebarSection } from "../../constants/constant";
import SideBarHeader from "../header/LoggedInHeader";
import { toast } from 'react-toastify';
import api from '../../api';
import { MenuInterface } from "./interface";
import Pagination from "./pagination";
import { UploadOutlined } from "@ant-design/icons";
import { Button, message, Spin, Upload } from "antd";
import BuffetMenuView from "../BuffetMenuCreation/MenuView";
import BuffetMenuCreation from "../BuffetMenuCreation";
import BuffetMenuEdit from "../BuffetMenuCreation/BuffetMenuEdit";



function BuffetMeal() {
  const [search, setSearch] = useState("");
  const ITEMS_PER_PAGE = 10; // Number of items to display per page
  const [currentPage, setCurrentPage] = useState(1);
  const [menuData, setMenuData] = useState<MenuInterface[]>([]);
  // const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [selectedMenuId, setSelectedMenuId] = useState<string | null>(null);
  const [showBuffetMenu, setShowBuffetMenu] = useState<boolean>(false);
  const [showEditBuffetMenu, setShowEditBuffetMenu] = useState<boolean>(false);
  const [main, setMain] = useState<boolean>(true);
  const [menuId1, setMenuId1] = useState<string>("");
  // const [formData, setFormData] = useState({});
  // const [userData, setUserData] = useState(null);
  const [defaultCurrency, setDefaultCurrency] = useState("");
  const navigate = useNavigate();
  const [totalPages, setTotalPages] = useState(1);//Math.ceil(branchData.length / ITEMS_PER_PAGE);
  const [showStatusUpdateModal, setShowStatusUpdateModal] = useState(false);
  const options = [
    { label: "Sarawak Spice Avocado", value: "Sarawak Spice Avocado" },
    { label: "Tangy Star Fruit Salad", value: "Tangy Star Fruit Salad" },
    { label: "Tangy Star Fruit Salad 2", value: "Tangy Star Fruit Salad 2" },
  ];
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleDeleteClose = () => setShowDeleteModal(false);
  const [selected, setSelected] = useState([]);
  const [menuId, setMenuId] = useState("");
  const handleStatusUpdateClose = () => setShowStatusUpdateModal(false);
  const handleStatusUpdateShow = (id: string) => {
    setShowStatusUpdateModal(true)
    setMenuId(id);
  };
  const [menuStatus, setMenuStatus] = useState("");

  useEffect(() => {
    // Reset the form when the selected options change
    //setShowForm(false);
    console.log("useeffect called  " + selected.length);
    if (selected.length === 1) {
      handleNext();
    }
    getAllMenu(currentPage);
  }, [selected, search, currentPage]);


  useEffect(() => {
    const loggedInUserData = localStorage.getItem('loggedInUser');
    if (loggedInUserData) {
      // setUserData(JSON.parse(loggedInUserData));
      const parsedData = JSON.parse(loggedInUserData);
      setDefaultCurrency(parsedData.restaurantdefaultCurrencyCode);
    }
  }, []);


  const handleDeleteShow = (id: string) => {
    setShowDeleteModal(true);
    setMenuId(id);
  }

  // const viewMenu = (id: string) => {
  //   setShowDeleteModal(true);
  //   setMenuId(id);
  // }

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchText = e.target.value;
    console.log("Search Text: ", searchText); // Log the search text
    setSearch(searchText);
    setCurrentPage(1); // Reset to the first page whenever search text changes

  };

  const getAllMenu = async (pageNo) => {
    try {
      const response = await api.get(`/api/restaurant/buffet-menu/?page=${pageNo}&limit=${ITEMS_PER_PAGE}&searchText=${search}`);
      const responseData = response.data.data[0];
      const buffetItems = responseData.data;
      const totalItems = responseData.total;
      const totalPages = responseData.pages;
  
      console.log("Menu Items:", buffetItems);
      console.log("Total Items:", totalItems);
      console.log("Total Pages:", totalPages);
  
      setMenuData(buffetItems);
      setTotalPages(totalPages);
    } catch (error) {
      console.log('Error fetching menu data:', error);
      // message.error("Error in API call!")
    }
  };
  
  // const nextPage = () => {
  //   setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));

  // };

  // const prevPage = () => {
  //   setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));

  // };

  // const goToPage = (pageNumber: any) => {
  //   setCurrentPage(Math.min(Math.max(pageNumber, 1), totalPages));

  // };
  const deleteMenu = async () => {
    // setLoading(true);
    try {
      await api.delete('/api/restaurant/buffet-menu/' + menuId).then(
        function (response) {
          console.log("sucess respp");
          toast.success("deleted Successfully!");
          // setLoading(false);
          console.log(response.data);
          setMenuId("");
          getAllMenu(currentPage);
          setShowDeleteModal(false);
          return response.data
        }
      ).catch(
        function (error) {
          console.log('Show error notification!');
          message.error("Can't proceed with deletion, Selected menu is attached to active branches")
          // setLoading(false);
          console.log(error);
          return Promise.reject(error);
        }
      );

    } catch (error) {
      console.error("There was an error!", error);
    }

  };

  const [showNav, setShowNav] = useState(true);
  const prepareAddMenu = () => {
    navigate("/buffet-menu-creation");
    // var myModal = new bootstrap.Modal(
    //   document.getElementById("menu-modal"),
    //   {}
    // );
    // myModal.show();
  };

  const handleInputChange = (e: { target: { name: any; value: any } }) => {
    // const { name, value } = e.target;
    // setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleNext = () => {
    setCount((prevCount) => prevCount + 1);
  };

  // const handlePrevious = () => {
  //   setCount((prevCount) => Math.max(1, prevCount - 1));
  // };


  const updateStatus = async () => {
    // setLoading(true);
    console.log(" update status ");
    try {
      await api.put('/api/restaurant/buffet-menu/status/' + menuId + "?statusCode=" + menuStatus).then(
        function (response) {
          message.success("Status updated Successfully!");
          // setLoading(false);
          console.log(response.data);
          handleStatusUpdateClose();
          setMenuId("");
          setMenuStatus("");
          getAllMenu(currentPage);
          return response.data
        }
      ).catch(
        function (error) {
          console.log('Show error notification!');
          message.error("Error in API call!")
          // setLoading(false);
          console.log(error);
          return Promise.reject(error);
        }
      );
    } catch (error) {
      console.error("There was an error!", error);
    }
  };

  const renderInputs = () => {
    const inputs = [];
    for (let i = 1; i <= selected.length; i++) {
      inputs.push(
        <div className="row" key={i}>
          <div className="col-md-6">
            <div className="mb-3">
              <label className="form-label">
                {`Dish Name : `} <span className="text-danger"> *</span>
              </label>
              <input
                type="text"
                value={options[i - 1].value}
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                onChange={handleInputChange}
              />
            </div>

            <div className="mb-3">
              <label className="form-label">
                {`Price ${i}: `}{defaultCurrency} <span className="text-danger"> *</span>
              </label>
              <input
                type="text"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <label className="form-label">
                {` Description : `} <span className="text-danger"> *</span>
              </label>
              <textarea
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                rows={5}
                onChange={handleInputChange}
              ></textarea>
            </div>
          </div>
          <hr />
        </div>
      );
    }
    return inputs;
  };

const [uploadImageModal, setUploadImageModal] = useState(false);
const [dishId, setDishId] = useState<string | null>(null);
const handleUploadShow = (itemId: string) => {
  setUploadImageModal(true);
  setDishId(itemId!);
};
const handleCloseUpload = () => {
  setUploadImageModal(false);
  setImageList([]);
  setVideoList([]);
  setDishId("");
};
const [imageList, setImageList] = useState([]);
const [videoList, setVideoList] = useState([]);
const [isLoading, setIsLoading] = useState(false);

const handleFileChange = async (key, { fileList }) => {
  if (key === "images") {
    let sizeErrorShown = false;

    // Filter valid images by checking file size for new uploads only (those with originFileObj)
    const validImages = await Promise.all(
      fileList
        .filter((file) => file.originFileObj) // Only process new files
        .map((file) => {
          return new Promise((resolve) => {
            // Check file size
            if (file.size / 1048576 > 1) {
              if (!sizeErrorShown) {
                message.error("Image size cannot exceed 1 MB.");
                sizeErrorShown = true; // Show error only once
              }
              resolve(null); // Exclude the large image
            } else {
              resolve(file); // Include valid image
            }
          });
        })
    );

    // If there is a valid image, replace the existing one
    if (validImages.filter((image) => image !== null).length > 0) {
      setImageList(validImages.filter((image) => image !== null).slice(0, 1)); // Only one image
    } 
    // If the new file is invalid (i.e., greater than 1 MB), we do nothing and keep the previous image.
  }
};


const handleUpload = async () => {
  if (imageList.length === 0 && videoList.length === 0) {
    message.error("Please select at least one image or video.");
    return;
  }
  setIsLoading(true);
  try {
    const formData = new FormData();

    imageList.forEach((image) => {
      formData.append("images", image.originFileObj);
    });

    videoList.forEach((video) => {
      formData.append("videos", video.originFileObj);
    });
    const previousImageUrls = imageList
    .filter((image) => !image.originFileObj && image.url) // Only images without originFileObj (old images)
    .map((image) => image.url) // Extract the URLs
    .join(","); // Join URLs with a comma

  // Append the concatenated URLs string to 'imageUrls' key
  if (previousImageUrls) {
    formData.append("imageUrls", previousImageUrls);
  }
    const response = await fetch(`/api/utility/buffet-menu/${dishId}`, {
      method: "PUT",
      headers: {
        authorization: "authorization-text",
      },
      body: formData,
    });

    if (response.ok) {
      message.success("Files uploaded successfully");
      getAllMenu(currentPage);
      handleCloseUpload();
      setImageList([]);
      setVideoList([]);
      setDishId(null);
    } else {
      message.error("File upload failed");
      getAllMenu(currentPage);
    }
  } catch (error) {
    console.error("Error uploading files:", error);
    message.error("File upload failed");
  } finally {
    setIsLoading(false);
  }
};
const handleRemove = (file) => {
  const confirmDelete = window.confirm(
    "Are you sure you want to delete this image?"
  );
  if (confirmDelete) {
    setImageList([]); // Remove the single image
    message.success("Image deleted successfully.");
  } else {
    return false; // Prevent removal if user cancels
  }
};
const handleViewMenu = (menuId1: string) => {
  setMenuId1(menuId1);
  setShowBuffetMenu(true); // Show the BuffetMenuView component
  setMain(false)
};
const handleClose = () => {
  setShowBuffetMenu(false); // Hide the BuffetMenuView component
  setMenuId1(""); // Reset the menu ID
  setMain(true)
};
const handleViewEditMenu = (menuId1: string) => {
  setMenuId1(menuId1);
  setShowEditBuffetMenu(true); // Show the BuffetMenuView component
  setMain(false)
};
const handleEditClose = () => {
  setShowEditBuffetMenu(false); // Hide the BuffetMenuView component
  setMenuId1(""); // Reset the menu ID
  setMain(true)
  getAllMenu(currentPage);
};

useEffect(() => {
  if (dishId) {
    getMenuDetails(dishId); // Call only if menuId1 is present
  }
}, [dishId]);


const getMenuDetails = async (menuId: string | null) => {
  try {
    var url = "/api/restaurant/buffet-menu/" + menuId;
    const response = await api.get(url);
    console.log("success menu details response", response.data);
    const existingImages = response.data.data.imageAttachement.map(
      (image: any) => ({
        uid: image._id, // Use _id as uid
        name: image.url.split("/").pop(), // Extract the file name from the URL
        status: "done", // Mark as already uploaded
        url: image.url, // Use the URL from dishDetailData
        originFileObj: null, // Not needed for already uploaded images
      })
    );

    // Now set the imageList after data is fetched
    setImageList(existingImages);
    
  } catch (error) {
    console.error("Error fetching dish data:", error);
    message.error(error.response.data.message);
  }
};

// if (showBuffetMenu) {
//   return <BuffetMenuView menuid={menuId1} handleClose={handleClose} />;
// }

  return (
    <>
    {main && (
      <div className={`body-area${showNav ? " body-pd" : ""}`}>

        <SideBarHeader showNav={showNav} setShowNav={setShowNav} />
        <div className={`l-navbar${showNav ? " showSideNav" : ""}`}>
          <nav className="sideNav">
            <SideMenu />
          </nav>
        </div>
        <div className="pt-4 pb-4">
          <section className="main shadow-sm">
            <div className="row mb-4">
              <div className="col">
                {/* <h4>{SidebarSection.MENU_LIST}</h4> */}
                <h4>Buffet Menu List</h4>

              </div>
              <div className="col text-end d-flex">
                <input
                  placeholder="Search by name..."
                  value={search}
                  onChange={(e: any) => handleSearchChange(e)}
                  type="text"
                  name="search"
                  className="form-control search"
                  id="Search"
                  aria-describedby="emailHelp"
                />
                <button
                  type="button"
                  className="btn addbtn"
                  onClick={prepareAddMenu}
                >
                  <i className="bi bi-clipboard-plus "></i>
                </button>
              </div>
            </div>
            <div className="row">
              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead>
                    <tr className="table-active">
                      <th scope="col">#</th>
                      <th>NAME</th>
                      <th>MENU CODE</th>
                      <th>PRICE ({defaultCurrency})</th>
                      <th>DESCRIPTION</th>

                      <th>STATUS</th>
                      <th scope="col">ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {menuData !== null &&
                      (menuData as any[]).map((item, index) => (
                        <tr key={index}>

                          <td>{index + 1}</td>
                          <td>{item.name}</td>
                          <td>{item.code}</td>
                          <td>{item.totalPrice}</td>
                          <td>{item.description}</td>
                          <td>
                            {item.status === "active" ? (
                              <span className="badge bg-success rounded-pill">
                                Active
                              </span>
                            ) : (
                              <span className="badge bg-error rounded-pill"></span>
                            )}
                            {item.status === "suspended" ? (
                              <span className="badge bg-error rounded-pill">
                                Suspended
                              </span>
                            ) : (
                              <span className="badge bg-error rounded-pill"></span>
                            )}
                            {item.status === "creating" ? (
                              <span className="badge bg-warning rounded-pill">
                                Creating
                              </span>
                            ) : (
                              <span className="badge bg-error rounded-pill"></span>
                            )}
                            {item.status === "waiting_for_approval" ? (
                              <span className="badge bg-warning rounded-pill">
                                Waiting for approval
                              </span>
                            ) : (
                              <span className="badge bg-error rounded-pill"></span>
                            )}
                            &nbsp;
                            <button

                              className="btn-action rounded-circle act-btn"
                              onClick={() =>
                                handleStatusUpdateShow(item._id)
                              }
                            >
                              <i className="bi bi-pencil-square"></i>
                            </button>
                          </td>
                          <td>
                          <button
                            onClick={() => handleViewEditMenu(item._id)}
                            className="btn-action rounded-circle act-btn"
                          >
                            <i className="bi bi-pencil-square"></i>
                          </button>
                            {/* <Link
                              to={'/buffet-menu-creation/' + item._id}
                              className="btn-action rounded-circle act-btn"
                            >
                              <i className="bi bi-pencil-square"></i>
                            </Link> */}
                            &nbsp;
                            {item.status === 'active' ? (
                                <button
                                  className="btn-action rounded-circle act-btn m-1"
                                  onClick={() => {
                                    message.error("Buffet Menu is in active state. can't delete");
                                  }}
                                >
                                  <i className="bi bi-trash "></i>
                                </button>
                              ) : (
                                <button
                              onClick={() => handleDeleteShow(item._id)}
                              className="btn-action rounded-circle act-btn "
                            >
                              <i className="bi bi-trash "></i>
                            </button>
                              )}
                            
                            &nbsp;
                            <button
                            onClick={() => handleViewMenu(item._id)}
                            className="btn-action rounded-circle act-btn"
                          >
                            <i className="bi bi-eye"></i>
                          </button>

                            {/* <Link
                              to={'/buffet-menu-view/' + item._id}
                              className="btn-action rounded-circle act-btn"
                            >
                              <i className="bi bi-eye"></i>
                            </Link> */}
                            &nbsp;
                            <button
                                className="btn-action rounded-circle act-btn m-1"
                                onClick={() => {
                                 
                                  handleUploadShow(item._id);
                                }}
                              >
                                <i className="bi bi-upload"></i>
                              </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                <div className=" d-flex  justify-content-end pe-md-5">

                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    setCurrentPage={setCurrentPage}
                  />

                </div>
              </div>
            </div>
          </section>
        </div>  
      </div>
    )}

      <div
        className="modal fade"
        id="menu-modal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content ">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Menu Creation
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <form>
              <div className="modal-body">
                {/* <div className="row"> */}
                <div className="row">
                  <div className="col-md-12">
                    <label className="form-label">
                      Menu Name<span className="text-danger"> *</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter menu name"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                    />
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="mb-3 col-md-12">
                    <label className="form-label">
                      Select category to which you want add below selected
                      dishes <span className="text-danger"> *</span>
                    </label>
                    <div>
                      <select className="form-control down-arrow">
                        <option value="volvo">Breakfast</option>
                        <option value="saab">Lunch</option>
                        <option value="mercedes">Dinner</option>
                        <option value="audi">Starters</option>
                        <option value="audi">Main Course</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <label className="form-label">
                      Dish <span className="text-danger"> *</span>
                    </label>
                    <MultiSelect
                      options={options}
                      value={selected}
                      onChange={setSelected}
                      labelledBy="Select"
                    />
                  </div>
                </div>
                {/* </div> */}
                <br />
                <hr />
                {count > 0 && (
                  <h6>
                    {" "}
                    <b>Total Dishes Selected: {selected.length} </b>
                  </h6>
                )}
                <hr />
                <div className="opto-1">{renderInputs()}</div>
              </div>
              <div className="modal-footer">
                {/* <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Submit</button> */}
                <button type="button" className="btn btn-primary opto">
                  Save & Continue
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>


      {showDeleteModal && (
        <div className="modal" tabIndex={-1} style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Delete branch?
                </h1>


                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleDeleteClose}
                ></button>
              </div>
              <div className="delete-text"> Are you sure you want to delete?</div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={handleDeleteClose}
                >
                  Cancel
                </button>
                <button type="button"
                  onClick={deleteMenu} className="btn btn-primary">
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showStatusUpdateModal && (
        <div className="modal" tabIndex={-1} style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Update status?
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleStatusUpdateClose}
                ></button>
              </div>
              <div className="delete-text">
                <div className="mb-12 col-md-12">
                  <label className="form-label">
                    Select status <span className="text-danger"> *</span>
                  </label>
                  <div>
                    <select className="form-control down-arrow" value={menuStatus} onChange={(e) => setMenuStatus(e.target.value)} >
                      <option value="">Select</option>
                      <option value="creating">Menu is being created</option>
                      {/* <option value="waiting_for_approval">Dish is waiting for approval</option> */}
                      {/* <option value="approved">Dish has been approved</option> */}
                      <option value="active">Menu is active</option>
                      {/* <option value="send_for_correction">Dish needs correction</option> */}
                      {/* <option value="inactive">Dish is inactive</option> */}
                      <option value="suspended">Menu is suspended</option>
                    </select>
                  </div>
                </div></div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={handleStatusUpdateClose}>
                  Cancel
                </button>
                <button type="button"
                  onClick={updateStatus} className="btn btn-primary">
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
       {uploadImageModal && (
        <div
          className="modal"
          tabIndex={-1}
          style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Upload Buffet Menu Images
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleCloseUpload}
                ></button>
              </div>
              <section className="main shadow-sm">
                {isLoading ? (
                  <div className="text-center my-4">
                    <Spin tip="Uploading... Please wait" />
                  </div>
                ) : (
                  <>
                    <div className="row ">
                      <div className="col-lg-6 col-md-6 text-center">
                      <Upload
                          multiple={false} // Allow only one image to be uploaded
                          listType="picture-card" // Display images as thumbnails
                          beforeUpload={() => false} // Disable auto upload
                          fileList={imageList} // Use the imageList state
                          onChange={(info) => handleFileChange("images", info)} // Handle file change
                          onRemove={handleRemove} // Handle remove action
                        >
                          <Button
                            className="text-start"
                            style={{ width: "310px", height: "103px" }}
                            icon={<UploadOutlined />}
                          >
                            <b>Upload</b>
                            <span className="text-danger"> *</span>
                          </Button>
                        </Upload>
                      </div>
                      {/* <div className="col-lg-6 col-md-6 text-center">
                        <Upload
                          multiple
                          beforeUpload={() => false}
                          fileList={videoList}
                          onChange={(info) => handleFileChange("videos", info)}
                        >

                          <Button
                            className="text-start"
                            style={{ width: "310px", height: "60px" }}
                            icon={<UploadOutlined />}
                          >
                            Dish video
                          </Button>
                          <span> (*Please select only one video)</span>
                        </Upload>
                      </div> */}
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-bs-dismiss="modal"
                        onClick={handleCloseUpload}
                      >
                        Close
                      </button>
                      <button
                        onClick={handleUpload}
                        type="submit"
                        className="btn btn-primary"
                      >
                        Save
                      </button>
                      
                    </div>
                  </>
                )}
              </section>
            </div>
          </div>
        </div>
      )}
      {showBuffetMenu && (
        <BuffetMenuView menuid={menuId1} handleClose={handleClose} />
      )}
      {showEditBuffetMenu && (
        <BuffetMenuEdit menuid={menuId1} handleClose={handleEditClose} />
      )}
    </>
  );
}

export default BuffetMeal;