import React, { useEffect, useState } from "react";
import "./login.scss";
import userIcon from "./img/user.png";
import api from "../../api";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { login } from "../redux/authGuard/userAuthenticate";
import { ToastContainer, toast } from "react-toastify";
import { saveUserData } from "../redux/loginRestaurantData/loginRestaurantData";

export default function Login() {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    // Scroll to top when the component mounts
    window.scrollTo(0, 0);
// Check if the page has already been reloaded
const hasReloaded = sessionStorage.getItem('hasReloaded');

// If not reloaded, reload the page and set the flag
if (!hasReloaded) {
  sessionStorage.setItem('hasReloaded', 'true');
  window.location.reload();
}

// Scroll to the top of the page after reload
window.scrollTo(0, 0);  }, []);

  //Function for Login
  const Login = async () => {
    dispatch(login());
    let payload = {
      email: email,
      password: password,
    };
    try {
      const response = await api.post("/api/identity/login/", payload);
      toast.success(response.data.message);
      navigate("/sidebar");
      console.log("login data", response.data.data);
      dispatch(saveUserData(response.data.data));

      localStorage.setItem("loggedInUser", JSON.stringify(response.data.data));

      // const response = await api
      //   .post("api/identity/login", payload)
      //   .then(function (response) {
      //     console.log("sucess respp");
      //     toast.success("Login Successfull!");
      //     navigate("/sidebar");
      //     console.log("login data", response.data.data);
      //     dispatch(saveUserData(response.data.data));

      //     localStorage.setItem(
      //       "loggedInUser",
      //       JSON.stringify(response.data.data)
      //     );
      //     return response.data;
      //   })
      //   .catch(function (error) {
      //     console.log("Show error notification!");
      //     toast.error("Error in api call!");
      //     console.log("login error", error);
      //     return Promise.reject(error);
      //   });
    } catch (error: any) {
      console.error("There was an error!", error);
      toast.error(error.response.data.message);
    }
  };
  return (
    <>
      {/* ======= About Section ======= */}
      <div className="container">
        <div className="row">
          <div className="col-md-4 offset-md-4">
            <br />
            <br />
            <br />
            <div className="shadow card my-5">
              <form className="card-body cardbody-color">
                <div className="text-center">
                  <img
                    src={userIcon}
                    className="img-fluid profile-image-pic img-thumbnail rounded-circle my-3"
                    width="200px"
                    alt="profile"
                  />
                </div>
                <div className="mb-3">
                  <input
                    value={email}
                    onChange={(e: any) => setEmail(e.target.value)}
                    type="text"
                    className="form-control"
                    id="Username"
                    aria-describedby="emailHelp"
                    placeholder="Email"
                  />
                </div>
                <div className="mb-3">
                  <input
                    value={password}
                    onChange={(e: any) => setPassword(e.target.value)}
                    type="password"
                    className="form-control"
                    id="password"
                    placeholder="Password"
                  />
                </div>
                <div className="text-center">
                  <button
                    onClick={Login}
                    type="button"
                    className="btn btn-color px-3 mb-3 w-100"
                  >
                    Login
                  </button>
                  <ToastContainer />
                </div>
                <div
                  id="emailHelp"
                  className="form-text text-center mb-3 text-dark"
                >
                  {" "}
                  <Link to="/forgot-password" className="text-dark fw-bold">
                    {" "}
                    Forgot password?
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* End About Section */}
    </>
  );
}
