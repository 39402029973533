import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SideMenu from "../sidemenu";
import { ToastContainer, toast } from "react-toastify";
import api from "../../api";
import { CategoryInterface } from "../Category/InterFace";
import { Button, message } from "antd";
import Loader from "../Loader";
import { MultiSelect } from "react-multi-select-component";
import SideBarHeader from "../header/LoggedInHeader";
import { DishDetailsInterface } from "./DishDetailsInterface";

interface Option {
  value: string;
  label: string;
  code: string;
  description: string;
  price: number;
}

interface Item {
  _id: string;
  name: string;
  code: string;
  description: string;
  price: number;
}

interface Data {
  _id: string;
  dishes: Array<Data1>;
}

interface Data1 {
  _id: string;
  label: string;
  description: string;
  price: number;
}
interface Data2 {
  id: string;
  description: string;
  price: number;
}
interface Data3 {
  categoryId: string,
  dish: Data2[]
}

function MenuCreation() {
  const [categoryListData, setCategoryListData] = useState<CategoryInterface[]>(
    []
  );
  const [showStatusUpdateModal, setShowStatusUpdateModal] = useState(false);
  const handleStatusUpdateClose = () => setShowStatusUpdateModal(false);
  const [loading, setLoading] = useState(false);
  // const [count, setCount] = useState(0);
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [dishDetails, setDishDetails] = useState<DishDetailsInterface>();
  const [dishOptions, setDishOptions] = useState<Option[]>([]);
  const [showNav, setShowNav] = useState(true);
  const [selected, setSelected] = useState<Option[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<Data[]>([]);
  // const [userData, setUserData] = useState(null);
  const [defaultCurrency, setDefaultCurrency] = useState("");
  let [errors, setErrors] = useState({
    name: "",
    categoryId: "",
    description: "",
    selected: "",
  });
  const { menuId } = useParams();

  const prepareViewDishDetails = (selectedDish: any) => {
    //debugger;
    console.log("dish detailss " + JSON.stringify(selectedDish));
    getDishDetails(selectedDish._id);
  };

  useEffect(() => {
    //debugger;
    getAllActiveCategory();
    getAllActiveDishes();
    if (menuId) {
      getMenuDetails(menuId);
    }
    const loggedInUserData = localStorage.getItem("loggedInUser");
    if (loggedInUserData) {
      // setUserData(JSON.parse(loggedInUserData));
      const parsedData = JSON.parse(loggedInUserData);
      setDefaultCurrency(parsedData.restaurantdefaultCurrencyCode);
    }
  }, [menuId]);

  const getAllActiveCategory = async () => {
    try {
      const response = await api.get("/api/restaurant/menu-category/active");
      console.log("success response", response.data);
      setCategoryListData(response.data.data);
    } catch (error) {
      console.error("Error fetching category data:", error);
      toast.error("Error fetching category data!");
    }
  };

  const getDishDetails = async (dishId: string) => {
    try {
      var url = "/api/restaurant/dish/" + dishId;
      const response = await api.get(url);
      console.log("success dish details response", response.data);
      setDishDetails(response.data.data);
      setShowStatusUpdateModal(true);
    } catch (error) {
      console.error("Error fetching dish data:", error);
      toast.error("Error fetching dish data!");
    }
  };

  const getAllActiveDishes = async () => {
    try {
      const response = await api.get(
        "/api/restaurant/dish/active?page=1&limit=100"
      );
      console.log("success response", response.data);
      const formattedOptions = response.data.data.dish.map((item: Item) => ({
        value: item._id,
        label: item.name,
        code: item.code,
        description: item.description,
      }));
      setDishOptions(formattedOptions);
    } catch (error) {
      console.error("Error fetching dish data:", error);
      toast.error("Error fetching dish data!");
    }
  };

  const handleChange = (e: any) => {
   // debugger;
    if (e.target === undefined) {
      console.log("multiselected selectedd " + e);
      JSON.stringify(e);
      setSelected(e);
      let selectedCats: Data[] = [];
      selectedCategories.forEach((element) => {
        if (element._id === categoryId) {
          // debugger;
          let dishes: Data1[] = [];
          selected.forEach((element1) => {
            dishes.push({
              _id: element1.value,
              description: element1.description,
              label: element1.label,
              price: element1.price,
            });
          });
          let val: Data = {
            _id: categoryId,
            dishes: dishes,
          };
          selectedCats.push(val);
        } else {
          // debugger;
          selectedCats.push(element);
        }
      });
      setSelectedCategories(selectedCats);
    } else {
      const { name, value } = e.target;
      if (name === "name") {
        setName(value);
      } else if (name === "categoryId") {
        setCategoryId(value);
        let selectedCats = selectedCategories;
        let status = false;
        selectedCategories.forEach((element) => {
          if (element._id === value) {
            status = true;
          }
        });
        if (status) {
          console.log("This category is already selected");
          let selectedData = [];
          dishOptions.forEach(element => {
            let status = checkIfSelectedDish(element.value, value);
            if (status === true) {
              selectedData.push(element);
            }
          });
          setSelected(selectedData);
        } else {
          setSelected([]);
          let val = {
            _id: value,
            dishes: [],
          };
          selectedCats.push(val);
          setSelectedCategories(selectedCats);
        }
      } else if (name === "description") {
        setDescription(value);
      } else if (name === "") {
        setSelected(value);
        let selectedCats: Data[] = [];
        selectedCategories.forEach((element) => {
          if (element._id === categoryId) {
            let dishes: Data1[] = [];
            selected.forEach((element1) => {
              dishes.push({
                _id: element1.value,
                description: element1.description,
                label: element1.label,
                price: element1.price,
              });
            });
            let val: Data = {
              _id: categoryId,
              dishes: dishes,
            };
            selectedCats.push(val);
          } else {
            selectedCats.push(element);
          }
        });
        setSelectedCategories(selectedCats);
      }
    }
  };

  const checkIfSelectedDish = (elementDishId: any, catId: any) => {
    let status = false;
    selectedCategories.forEach((element) => {
      if (element._id === catId) {
        element.dishes.forEach((element1) => {
          if (element1._id === elementDishId) {
            status = true;
          }
        });
      }
    });
    return status;
  }

  const handleChange1 = (e: any, i: any, j: any) => {
    const { name, value } = e.target;
    let selectedCats: Data[] = [];
    let k = 0;
    selectedCategories.forEach((element) => {
      let dishes: Data1[] = [];
      let m = 0;
      element.dishes.forEach((element1) => {
        if (i === k && m === j) {
          if (name === 'price') {
            dishes.push({
              _id: element1._id,
              description: element1.description,
              label: element1.label,
              price: parseInt(value)
            });
          } else {
            dishes.push({
              _id: element1._id,
              description: value,
              label: element1.label,
              price: element1.price,
            });
          }
        } else {
          dishes.push({
            _id: element1._id,
            description: element1.description,
            label: element1.label,
            price: element1.price,
          });
        }
        m = m + 1;
      });
      let val: Data = {
        _id: element._id,
        dishes: dishes,
      };
      selectedCats.push(val);
      k = k + 1;
    });
    setSelectedCategories(selectedCats);
  };

  const deleteDish = (i: any, j: any) => {
    let selectedCats: Data[] = [];
    let k = 0;
    selectedCategories.forEach((element) => {
      let dishes: Data1[] = [];
      let m = 0;
      element.dishes.forEach((element1) => {
        if (i === k && m === j) {

        } else {
          dishes.push({
            _id: element1._id,
            description: element1.description,
            label: element1.label,
            price: element1.price,
          });
        }
        m = m + 1;
      });
      let val: Data = {
        _id: element._id,
        dishes: dishes,
      };
      selectedCats.push(val);
      k = k + 1;
    });
    setSelectedCategories(selectedCats);
  };

  const isValid = () => {
    var valid = true;
    var newErrors = {
      name: "",
      categoryId: "",
      description: "",
      selected: "",
    };
    // Name validation
    if (!name.trim()) {
      newErrors = { ...newErrors, name: "Name is required" };
      valid = false;
    } else {
      newErrors = { ...newErrors, name: "" };
      console.log("name error cleared");
    }
    // Category
    if (!categoryId.trim()) {
      newErrors = { ...newErrors, categoryId: "Category is required" };
      valid = false;
    } else {
      newErrors = { ...newErrors, categoryId: "" };
      valid = false;
      console.log("Category error cleared");
    }
    // Description
    if (!description.trim()) {
      newErrors = { ...newErrors, description: "Description is required" };
      valid = false;
    } else {
      newErrors = { ...newErrors, description: "" };
      valid = true;
    }
    if (!valid) {
      setErrors(newErrors);
      console.log("error count: " + Object.keys(newErrors).length);
    }
    return valid;
  };

  useEffect(() => {
    let selectedCats: Data[] = [];
    selectedCategories.forEach((element) => {
      if (element._id === categoryId) {
        let dishes: Data1[] = [];
        selected.forEach((element1) => {
          dishes.push({
            _id: element1.value,
            description: element1.description,
            label: element1.label,
            price: element1.price,
          });
        });
        let val: Data = {
          _id: categoryId,
          dishes: dishes,
        };
        selectedCats.push(val);
      } else {
        selectedCats.push(element);
      }
    });
    setSelectedCategories(selectedCats);
  }, [selected]);

  const setCategoryDataInEditFlow = async (data: any) => {
    let selectedCats: Data[] = [];
    data.category.forEach(element => {
      let dishes: Data1[] = [];
      element.dish.forEach(element1 => {
        dishes.push({
          _id: element1._id,
          description: element1.description,
          label: element1.name,
          price: element1.price,
        });
      });
      let val: Data = {
        _id: element._id,
        dishes: dishes,
      };
      selectedCats.push(val);
    });
    setSelectedCategories(selectedCats);
  }

  const getMenuDetails = async (menuId: string) => {
    try {
      var url = "/api/restaurant/menu/" + menuId;
      const response = await api.get(url);
      console.log("success menu details response", response.data);
      setName(response.data.data.name);
      setDescription(response.data.data.description);
      setCategoryId(response.data.data.category[0]._id);
      setCategoryDataInEditFlow(response.data.data);
    } catch (error) {
      console.error("Error fetching dish data:", error);
      toast.error("Error fetching dish data!");
    }
  };

  const addOrUpdateMenu = async () => {
    if (isValid()) {
      let url = "";
      if (menuId === "" || menuId === undefined) {
        url = "/api/restaurant/menu/";
      } else {
        url = "/api/restaurant/menu/" + menuId;
      }
      let categoryData: Data3[] = [];
      selectedCategories.forEach(element => {
        let dishes: Data2[] = [];
        element.dishes.forEach(element1 => {
          let dish = {
            id: element1._id,
            description: element1.description,
            price: element1.price,
          }
          dishes.push(dish);
        });
        let cat = {
          categoryId: element._id,
          dish: dishes
        }
        if (null != dishes && dishes.length > 0)
          categoryData.push(cat)
      });

      let payload = {
        name: name,
        description: description,
        category: categoryData
      };

      try {
        if (menuId === "" || menuId === undefined) {
          const response = await api.post(url, payload);
          setLoading(false);

          message.success("Menu added Successfully!");
          console.log("success response", response.data);
        } else {
          const response = await api.put(url, payload);
          setLoading(false);
          
          message.success("Menu updated Successfully!");
          console.log("success response", response.data);
        }
        navigate("/menu");
      } catch (error) {
        console.error(error.response.data.message);
        setLoading(false);
        message.error(error.response.data.message);
      }
    }
  };

  const getCategoryName = (id: any) => {
    let name = null;
    categoryListData.forEach((element) => {
      if (element._id === id._id) {
        name = element.name;
      }
    });
    return name;
  };

  const closeCreation = () => {
    navigate("/menu");
  };

  const renderInputs2 = (i: any) => {
    const inputs: JSX.Element[] = [];
    for (let j = 0; j < selectedCategories[i].dishes.length; j++) {
      inputs.push(
        <div className="row">
          <div className="col-md-3">
            <label className="form-label">
              {`Dish Name : `} <span className="text-danger"> *</span>
            </label>
            <input
              type="text"
              value={selectedCategories[i].dishes[j].label}
              className="form-control"
              id={`dishName-${i}`}
              aria-describedby="emailHelp"
              readOnly
              disabled
            />
          </div>
          <div className="col-md-3">
            <label className="form-label">
              {`Price: `} [{defaultCurrency}]{" "}
              <span className="text-danger"> *</span>
            </label>
            <input
              type="number"
              className="form-control"
              id={`price-${i}`}
              name="price"
              value={selectedCategories[i]?.dishes[j]?.price}
              onChange={(e) => {
                handleChange1(e, i, j);
              }}
              aria-describedby="emailHelp"
            />
          </div>
          <div className="col-md-3">
            <label className="form-label">
              {` Description : `} <span className="text-danger"> *</span>
            </label>
            <textarea
              className="form-control"
              id={`description-${i}`}
              value={selectedCategories[i].dishes[j].description}
              aria-describedby="emailHelp"
              rows={2}
              name={`description-${i}`}
              onChange={(e) => {
                handleChange1(e, i, j);
              }}
            ></textarea>
          </div>
          <div className="col-md-3">
            <a
              className="btn-action rounded-circle act-btn"
              role="button"
              onClick={() => prepareViewDishDetails(selectedCategories[i].dishes[j])}
            >
              <i className="bi bi-eye"></i>
            </a>
            <a
              onClick={() => deleteDish(i, j)}
              className="btn-action rounded-circle act-btn"
              role="button"
            >
              <i className="bi bi-trash "></i>
            </a>
          </div>
        </div>
      );
    }
    return inputs;
  };

  const renderInputs = () => {
    const inputs: JSX.Element[] = [];
    for (let i = 0; i < selectedCategories.length; i++) {
      inputs.push(
        <div className="accordion" id={"accordionExample" + i}>
          <div className="card">
            <div className="card-header" id={"headingOne" + i}>
              <h2 className="mb-0">
                <button
                  className="btn btn-link"
                  type="button"
                  data-toggle="collapse"
                  data-target={"#collapseOne" + i}
                  aria-expanded="true"
                  aria-controls={"collapseOne" + i}
                >
                  {getCategoryName(selectedCategories[i])}
                </button>
              </h2>
            </div>

            <div
              id={"collapseOne" + i}
              className="collapse show"
              aria-labelledby={"headingOne" + i}
              data-parent={"#accordionExample" + i}
            >
              <div className="card-body opto-2">{renderInputs2(i)}</div>
            </div>
          </div>
        </div>
      );
    }
    return inputs;
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <div className={`body-area${showNav ? " body-pd" : ""}`}>
            <SideBarHeader showNav={showNav} setShowNav={setShowNav} />
            <div className={`l-navbar${showNav ? " showSideNav" : ""}`}>
              <nav className="sideNav">
                <SideMenu />
              </nav>
            </div>
            <div className="">
              <section className="main shadow-sm border-model">
                <div className="row mb-4">
                  <div className="col">
                    <h4 className="rest-info">Menu Creation</h4>
                  </div>
                  <div className="col text-end ">
                    <button
                      type="button"
                      onClick={() => closeCreation()}
                      className="btn-close "
                      aria-label="Close"
                    ></button>
                  </div>
                  <hr />
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <label className="form-label">
                      Menu Name<span className="text-danger"> *</span>
                    </label>
                    <div>
                      <input
                        type="text"
                        value={name}
                        name="name"
                        onChange={(e: any) => handleChange(e)}
                        placeholder="Enter menu name"
                        className="form-control"
                      />
                      {errors.name && (
                        <span className="error-msg">{errors.name}</span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <label className="form-label">
                      {` Description : `}{" "}
                      <span className="text-danger"> *</span>
                    </label>
                    <textarea
                      className="form-control"
                      value={description}
                      name="description"
                      onChange={(e: any) => handleChange(e)}
                      rows={1}
                    ></textarea>
                    {errors.description && (
                      <span className="error-msg">{errors.description}</span>
                    )}
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col-md-4 col-sm-12">
                    <div className="row">
                      <label className="form-label">
                        Select Category <span className="text-danger"> *</span>(If you want to add more categories then select one by one
                          and select dish)
                      </label>
                      <div>
                        <select
                          className="form-control down-arrow"
                          value={categoryId}
                          onChange={(e: any) => handleChange(e)}
                          name="categoryId"
                         >
                          <option value="">Select</option>
                          {categoryListData.map((item, index) => (
                            <option key={index} value={item._id}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                        {errors.categoryId && (
                          <span className="error-msg">{errors.categoryId}</span>
                        )}
                      </div>
                    </div>
                    <br />
                    <div className="row">
                      <label className="form-label">
                        Select Dishes <span className="text-danger"> *</span>
                      </label>
                      <MultiSelect
                        options={dishOptions}
                        value={selected}
                        onChange={handleChange}
                        labelledBy="Select"
                      />
                      {errors.selected && (
                        <span className="error-msg">{errors.selected}</span>
                      )}
                    </div>
                    <br />
                  </div>
                  <div className="col-md-8 col-sm-12">
                    <div className="opto-1">{renderInputs()}</div>
                  </div>
                </div>
              </section>
              <div className="text-center">
                <Button
                  style={{ background: "#ce1212", color: "white" }}
                  onClick={() => addOrUpdateMenu()}
                >
                  SUBMIT <i className="bx bx-chevron-right"></i>
                </Button>
              </div>
            </div>

          </div>
          {showStatusUpdateModal && (
            <div
              className="modal"
              tabIndex={-1} style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
            >
              <div className="modal-dialog modal-lg">
                <div className="modal-content ">
                  <div className="modal-header">
                    <h1 className="modal-title fs-5" id="exampleModalLabel">
                      Dish details
                    </h1>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={handleStatusUpdateClose}
                    ></button>
                  </div>
                  <table className="table table-bordered view-table infoTable">
                    <tbody>
                      <tr>
                        <th>Dish Name</th>
                        <td>{dishDetails?.name}</td>
                        <th>Base Production Cost[{defaultCurrency}]</th>
                        <td>{dishDetails?.productionCost}</td>
                      </tr>
                      <tr>
                        <th>No. of people who can consume </th>
                        <td>{dishDetails?.servingSize}</td>
                        <th>Description </th>
                        <td>{dishDetails?.description}</td>
                      </tr>
                      <tr>
                        <th>Avg. Preparation Time (in min.)</th>
                        <td>{dishDetails?.preparationTime}</td>
                        <th>Ingredients</th>
                        <td>
                          <td colSpan={3}>
                            <table className="sub-table">
                              <tbody>
                                <tr>
                                  <th>Name</th>
                                  <th>Quantity</th>
                                  <th>Unit</th>
                                  <th>Description </th>
                                </tr>
                                {dishDetails?.ingrediants.map((ingredient: any) => (
                                  <tr key={ingredient._id}>
                                    <td>{ingredient.name}</td>
                                    <td>{ingredient.quantity}</td>
                                    <td>{ingredient.unit}</td>
                                    <td>{ingredient.description}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </td>
                        </td>
                      </tr>
                      <tr>
                        <th>Dish Tagging</th>
                        <td>
                          {dishDetails &&
                            dishDetails?.categoryTag != null &&
                            (dishDetails?.categoryTag as any).map(
                              (item, index) => (
                                <ul key={index}>
                                  <li>{item}</li>
                                </ul>
                              )
                            )}
                        </td>
                        <th>
                          {/* Image */}
                        </th>
                        <td>
                          {/* <img src='img/menu/menu-item-1.png' height={40} width={40} alt="dish-image" /> */}
                        </td>
                      </tr>
                      <tr>
                        <tr>
                          <th>DISH IMAGE</th>
                          <td>
                            <img src={dishDetails?.imageAttachement[0]?.url ? dishDetails?.imageAttachement[0]?.url : '/img/menu/menu-item-2.png'} className="menu-img1 img-fluid" alt="" width={610} height={610} />
                          </td>
                          <th></th>
                          <td></td>
                        </tr>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      <ToastContainer />
    </>
  );
}

export default MenuCreation;
