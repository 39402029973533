import { useEffect, useState } from "react";
import RestaurantMenu from "./restaurantMenu";
import { ToastContainer} from "react-toastify";
export default function HomePage() {
  
    return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          
          <RestaurantMenu/>
        </div>
      </div>
      <ToastContainer />
    </div>

  );
}
