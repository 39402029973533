import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SideMenu from "../sidemenu";
import { ToastContainer, toast } from "react-toastify";
import api from "../../api";
import { CategoryInterface } from "../Category/InterFace";
import { Button, message, Spin, Upload } from "antd";
import Loader from "../Loader";
import { MultiSelect } from "react-multi-select-component";
import SideBarHeader from "../header/LoggedInHeader";
import Resizer from 'react-image-file-resizer';
import { UploadOutlined } from "@ant-design/icons";
import { DishDetailsInterface } from "./DishDetailsInterface";

interface Option {
  value: string;
  label: string;
  code: string;
  description: string;
  price: number;
}

interface Item {
  _id: string;
  name: string;
  code: string;
  description: string;
  price: number;
}

interface Data {
  _id: string;
  dishes: Array<Data1>;
}

interface Data1 {
  _id: string;
  label: string;
  description: string;
  price: number;
}
interface Data2 {
  id: string;
  // description: string;
  // price: number;
}
interface Data3 {
  categoryId: string;
  dish: Data2[];
}

function BuffetMenuCreation() {
  const [categoryListData, setCategoryListData] = useState<CategoryInterface[]>(
    []
  );
  const [showStatusUpdateModal, setShowStatusUpdateModal] = useState(false);
  const handleStatusUpdateClose = () => setShowStatusUpdateModal(false);
  const [loading, setLoading] = useState(false);
  // const [count, setCount] = useState(0);
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [totalPrice, setTotalPrice] = useState<number>();

  const [categoryId, setCategoryId] = useState("");
  const [dishDetails, setDishDetails] = useState<DishDetailsInterface>();
  const [dishOptions, setDishOptions] = useState<Option[]>([]);
  const [showNav, setShowNav] = useState(true);
  const [selected, setSelected] = useState<Option[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<Data[]>([]);
  // const [userData, setUserData] = useState(null);
  const [defaultCurrency, setDefaultCurrency] = useState("");
  let [errors, setErrors] = useState({
    name: "",
    categoryId: "",
    description: "",
    selected: "",
  });
  const { menuId } = useParams();

  const prepareViewDishDetails = (selectedDish: any) => {
    //debugger;
    console.log("dish detailss " + JSON.stringify(selectedDish));
    getDishDetails(selectedDish._id);
  };

  useEffect(() => {
    //debugger;
    getAllActiveCategory();
    getAllActiveDishes();
    if (menuId) {
      getMenuDetails(menuId);
    }
    const loggedInUserData = localStorage.getItem("loggedInUser");
    if (loggedInUserData) {
      // setUserData(JSON.parse(loggedInUserData));
      const parsedData = JSON.parse(loggedInUserData);
      setDefaultCurrency(parsedData.restaurantdefaultCurrencyCode);
    }
  }, [menuId]);

  const getAllActiveCategory = async () => {
    try {
      const response = await api.get("/api/restaurant/menu-category/active");
      console.log("success response", response.data);
      setCategoryListData(response.data.data);
    } catch (error) {
      console.error("Error fetching category data:", error);
      toast.error("Error fetching category data!");
    }
  };

  const getDishDetails = async (dishId: string) => {
    try {
      var url = "/api/restaurant/dish/" + dishId;
      const response = await api.get(url);
      console.log("success dish details response", response.data);
      setDishDetails(response.data.data);
      setShowStatusUpdateModal(true);
    } catch (error) {
      console.error("Error fetching dish data:", error);
      toast.error("Error fetching dish data!");
    }
  };

  const getAllActiveDishes = async () => {
    try {
      const response = await api.get(
        "/api/restaurant/dish/active?page=1&limit=100"
      );
      console.log("success response", response.data);
      const formattedOptions = response.data.data.dish.map((item: Item) => ({
        value: item._id,
        label: item.name,
        code: item.code,
        description: item.description,
      }));
      setDishOptions(formattedOptions);
    } catch (error) {
      console.error("Error fetching dish data:", error);
      toast.error("Error fetching dish data!");
    }
  };

  const handleChange = (e) => {
    if (e.target === undefined) {
      console.log("multiselected selectedd " + e);
      JSON.stringify(e);
      setSelected(e);
      let selectedCats = [];
      selectedCategories.forEach((element) => {
        if (element._id === categoryId) {
          let dishes = [];
          selected.forEach((element1) => {
            dishes.push({
              _id: element1.value,
              description: element1.description,
              label: element1.label,
              price: element1.price,
            });
          });
          let val = {
            _id: categoryId,
            dishes: dishes,
          };
          selectedCats.push(val);
        } else {
          selectedCats.push(element);
        }
      });
      setSelectedCategories(selectedCats);
    } else {
      const { name, value } = e.target;
      if (name === "name") {
        setName(value);
      } else if (name === "categoryId") {
        setCategoryId(value);
        let selectedCats = selectedCategories;
        let status = false;
        selectedCategories.forEach((element) => {
          if (element._id === value) {
            status = true;
          }
        });
        if (status) {
          console.log("This category is already selected");
          let selectedData = [];
          dishOptions.forEach((element) => {
            let status = checkIfSelectedDish(element.value, value);
            if (status === true) {
              selectedData.push(element);
            }
          });
          setSelected(selectedData);
        } else {
          setSelected([]);
          let val = {
            _id: value,
            dishes: [],
          };
          selectedCats.push(val);
          setSelectedCategories(selectedCats);
        }
      } else if (name === "description") {
        setDescription(value);
      } else if (name === "totalPrice") {
        setTotalPrice(parseFloat(value)); // Ensure totalPrice is a number
      } else if (name === "") {
        setSelected(value);
        let selectedCats = [];
        selectedCategories.forEach((element) => {
          if (element._id === categoryId) {
            let dishes = [];
            selected.forEach((element1) => {
              dishes.push({
                _id: element1.value,
                description: element1.description,
                label: element1.label,
                price: element1.price,
              });
            });
            let val = {
              _id: categoryId,
              dishes: dishes,
            };
            selectedCats.push(val);
          } else {
            selectedCats.push(element);
          }
        });
        setSelectedCategories(selectedCats);
      }
    }
  };

  const checkIfSelectedDish = (elementDishId: any, catId: any) => {
    let status = false;
    selectedCategories.forEach((element) => {
      if (element._id === catId) {
        element.dishes.forEach((element1) => {
          if (element1._id === elementDishId) {
            status = true;
          }
        });
      }
    });
    return status;
  };

  const handleChange1 = (e: any, i: any, j: any) => {
    const { name, value } = e.target;
    let selectedCats: Data[] = [];
    let k = 0;
    selectedCategories.forEach((element) => {
      let dishes: Data1[] = [];
      let m = 0;
      element.dishes.forEach((element1) => {
        if (i === k && m === j) {
          if (name === "price") {
            dishes.push({
              _id: element1._id,
              description: element1.description,
              label: element1.label,
              price: parseInt(value),
            });
          } else {
            dishes.push({
              _id: element1._id,
              description: value,
              label: element1.label,
              price: element1.price,
            });
          }
        } else {
          dishes.push({
            _id: element1._id,
            description: element1.description,
            label: element1.label,
            price: element1.price,
          });
        }
        m = m + 1;
      });
      let val: Data = {
        _id: element._id,
        dishes: dishes,
      };
      selectedCats.push(val);
      k = k + 1;
    });
    setSelectedCategories(selectedCats);
  };

  const deleteDish = (i: any, j: any) => {
    let selectedCats: Data[] = [];
    let k = 0;
    selectedCategories.forEach((element) => {
      let dishes: Data1[] = [];
      let m = 0;
      element.dishes.forEach((element1) => {
        if (i === k && m === j) {
        } else {
          dishes.push({
            _id: element1._id,
            description: element1.description,
            label: element1.label,
            price: element1.price,
          });
        }
        m = m + 1;
      });
      let val: Data = {
        _id: element._id,
        dishes: dishes,
      };
      selectedCats.push(val);
      k = k + 1;
    });
    setSelectedCategories(selectedCats);
  };

  const isValid = () => {
    var valid = true;
    var newErrors = {
      name: "",
      categoryId: "",
      description: "",
      selected: "",
    };
    // Name validation
    if (!name.trim()) {
      newErrors = { ...newErrors, name: "Name is required" };
      valid = false;
    } else {
      newErrors = { ...newErrors, name: "" };
      console.log("name error cleared");
    }
    // Category
    if (!categoryId.trim()) {
      newErrors = { ...newErrors, categoryId: "Category is required" };
      valid = false;
    } else {
      newErrors = { ...newErrors, categoryId: "" };
      valid = false;
      console.log("Category error cleared");
    }
    // Description
    if (!description.trim()) {
      newErrors = { ...newErrors, description: "Description is required" };
      valid = false;
    } else {
      newErrors = { ...newErrors, description: "" };
      valid = true;
    }
    if (!valid) {
      setErrors(newErrors);
      console.log("error count: " + Object.keys(newErrors).length);
    }
    return valid;
  };

  useEffect(() => {
    let selectedCats: Data[] = [];
    selectedCategories.forEach((element) => {
      if (element._id === categoryId) {
        let dishes: Data1[] = [];
        selected.forEach((element1) => {
          dishes.push({
            _id: element1.value,
            description: element1.description,
            label: element1.label,
            price: element1.price,
          });
        });
        let val: Data = {
          _id: categoryId,
          dishes: dishes,
        };
        selectedCats.push(val);
      } else {
        selectedCats.push(element);
      }
    });
    setSelectedCategories(selectedCats);
  }, [selected]);

  const setCategoryDataInEditFlow = async (data: any) => {
    let selectedCats: Data[] = [];
    data.category.forEach((element) => {
      let dishes: Data1[] = [];
      element.dish.forEach((element1) => {
        dishes.push({
          _id: element1._id,
          description: element1.description,
          label: element1.name,
          price: element1.price,
        });
      });
      let val: Data = {
        _id: element._id,
        dishes: dishes,
      };
      selectedCats.push(val);
    });
    setSelectedCategories(selectedCats);
  };

  const getMenuDetails = async (menuId: string) => {
    try {
      var url = "/api/restaurant/buffet-menu/" + menuId;
      const response = await api.get(url);
      console.log("success menu details response", response.data);
      setName(response.data.data.name);
      setDescription(response.data.data.description);
      setTotalPrice(response.data.data.totalPrice);
      setCategoryId(response.data.data.category[0]._id);
      setCategoryDataInEditFlow(response.data.data);
    } catch (error) {
      console.error("Error fetching dish data:", error);
      message.error(error.response.data.message);
    }
  };

  const addOrUpdateMenu = async () => {
    if (isValid()) {
      let url = "";
      if (menuId === "" || menuId === undefined) {
        url = "/api/restaurant/buffet-menu/";
      } else {
        url = "/api/restaurant/buffet-menu/" + menuId;
      }

      let categoryData: { categoryId: string; dishId: string[] }[] = [];

    selectedCategories.map((element) => {
    let dishIds = element.dishes.map((dish) => dish._id);
    let cat = {
        categoryId: element._id,
        dishId: dishIds, // Change 'dish' to 'dishId'
    };
    if (dishIds != null && dishIds.length > 0) {
        categoryData.push(cat);
    }

    return cat; // Or return { categoryId: element._id, dishId: dishIds } directly
});

      let payload = {
        name: name,
        description: description,
        totalPrice: totalPrice, // Ensure totalPrice is a number
        category: categoryData,
      };

      try {
        if (menuId === "" || menuId === undefined) {
          const response = await api.post(url, payload);
          setLoading(false);
          message.success("Menu added Successfully!");
          console.log("success response", response.data);
          console.log("===========");
          console.log(response.data.data.createdMenuId);
          handleUploadShow(response.data.data.createdMenuId);
        } else {
          const response = await api.put(url, payload);
          setLoading(false);
          message.success("Menu updated Successfully!");
          console.log("success response", response.data);
          navigate("/buffet-meal");
        }
        
      } catch (error) {
        console.error("Error adding menu:", error);
        setLoading(false);
        toast.error(error.response.data.message);
      }
    }
  };

  const getCategoryName = (id: any) => {
    let name = null;
    categoryListData.forEach((element) => {
      if (element._id === id._id) {
        name = element.name;
      }
    });
    return name;
  };

  const closeCreation = () => {
    navigate("/buffet-meal");
  };

  const renderInputs2 = (i: any) => {
    const inputs: JSX.Element[] = [];
    for (let j = 0; j < selectedCategories[i].dishes.length; j++) {
      inputs.push(
        <div className="row">
          <div className="col-md-3">
            <label className="form-label">
              {`Dish Name : `} <span className="text-danger"> *</span>
            </label>
            <input
              type="text"
              value={selectedCategories[i].dishes[j].label}
              className="form-control"
              id={`dishName-${i}`}
              aria-describedby="emailHelp"
              readOnly
              disabled
            />
          </div>

          <div className="col-md-3">
            <label className="form-label">
              {` Description : `} <span className="text-danger"> *</span>
            </label>
            <input
              type="text"
              id={`description-${i}`}
              value={selectedCategories[i].dishes[j].description}
              className="form-control"
              name={`description-${i}`}
              readOnly
            ></input>
          </div>
          <div className="col-md-3">
            <button
              className="btn-action rounded-circle act-btn"
              role="button"
              onClick={() =>
                prepareViewDishDetails(selectedCategories[i].dishes[j])
              }
            >
              <i className="bi bi-eye"></i>
            </button>
            &nbsp;
            <button
              onClick={() => deleteDish(i, j)}
              className="btn-action rounded-circle act-btn"
              role="button"
            >
              <i className="bi bi-trash "></i>
            </button>
          </div>
        </div>
      );
    }
    return inputs;
  };

  const renderInputs = () => {
    const inputs: JSX.Element[] = [];
    for (let i = 0; i < selectedCategories.length; i++) {
      inputs.push(
        <div className="accordion" id={"accordionExample" + i}>
          <div className="card">
            <div className="card-header" id={"headingOne" + i}>
              <h2 className="mb-0">
                <button
                  className="btn btn-link"
                  type="button"
                  data-toggle="collapse"
                  data-target={"#collapseOne" + i}
                  aria-expanded="true"
                  aria-controls={"collapseOne" + i}
                >
                  {getCategoryName(selectedCategories[i])}
                </button>
              </h2>
            </div>

            <div
              id={"collapseOne" + i}
              className="collapse show"
              aria-labelledby={"headingOne" + i}
              data-parent={"#accordionExample" + i}
            >
              <div className="card-body opto-2">{renderInputs2(i)}</div>
            </div>
          </div>
        </div>
      );
    }
    return inputs;
  };
  const [uploadImageModal, setUploadImageModal] = useState(false);
  const [dishId, setDishId] = useState<string | null>(null);
  const handleCloseUpload = () => {
    setUploadImageModal(false);
    setImageList([]);
    setVideoList([]);
    navigate("/buffet-meal");
  };
  const handleUploadShow = (itemId: string) => {
    setUploadImageModal(true);
    setDishId(itemId!);
  };
  const [imageList, setImageList] = useState([]);
  const [videoList, setVideoList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const dataURLtoBlob = (dataURL) => {
  const [header, data] = dataURL.split(',');
  const mime = header.match(/:(.*?);/)[1];
  const binary = atob(data);
  const array = [];
  
  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  
  return new Blob([new Uint8Array(array)], { type: mime });
};

const handleFileChange = async (key, { fileList }) => {
  if (key === "images") {
    let sizeErrorShown = false;

    // Filter valid images by checking file size for new uploads only (those with originFileObj)
    const validImages = await Promise.all(
      fileList
        .filter((file) => file.originFileObj) // Only process new files
        .map((file) => {
          return new Promise((resolve) => {
            // Check file size
            if (file.size / 1048576 > 1) {
              if (!sizeErrorShown) {
                message.error("Image size cannot exceed 1 MB.");
                sizeErrorShown = true; // Show error only once
              }
              resolve(null); // Exclude the large image
            } else {
              resolve(file); // Include valid image
            }
          });
        })
    );

    // If there is a valid image, replace the existing one
    if (validImages.filter((image) => image !== null).length > 0) {
      setImageList(validImages.filter((image) => image !== null).slice(0, 1)); // Only one image
    } 
    // If the new file is invalid (i.e., greater than 1 MB), we do nothing and keep the previous image.
  }
};
  

  const handleUpload = async () => {
    if (imageList.length === 0 && videoList.length === 0) {
      message.error("Please select at least one image or video.");
      return;
    }
    setIsLoading(true);
    try {
      const formData = new FormData();

      imageList.forEach((image) => {
        formData.append("images", image.originFileObj);
      });

      videoList.forEach((video) => {
        formData.append("videos", video.originFileObj);
      });

      const previousImageUrls = imageList
    .filter((image) => !image.originFileObj && image.url) // Only images without originFileObj (old images)
    .map((image) => image.url) // Extract the URLs
    .join(","); // Join URLs with a comma

  // Append the concatenated URLs string to 'imageUrls' key
  if (previousImageUrls) {
    formData.append("imageUrls", previousImageUrls);
  }
      const response = await fetch(`/api/utility/buffet-menu/${dishId}`, {
        method: "PUT",
        headers: {
          authorization: "authorization-text",
        },
        body: formData,
      });

      if (response.ok) {
        message.success("Files uploaded successfully");
        
        handleCloseUpload();
        setImageList([]);
        setVideoList([]);
        navigate("/buffet-meal");
      } else {
        message.error("File upload failed");
        
      }
    } catch (error) {
      console.error("Error uploading files:", error);
      message.error("File upload failed");
    } finally {
      setIsLoading(false);
    }
  };
  const handleRemove = (file) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this image?"
    );
    if (confirmDelete) {
      setImageList([]); // Remove the single image
      message.success("Image deleted successfully.");
    } else {
      return false; // Prevent removal if user cancels
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <div className={`body-area${showNav ? " body-pd" : ""}`}>
            <SideBarHeader showNav={showNav} setShowNav={setShowNav} />
            <div className={`l-navbar${showNav ? " showSideNav" : ""}`}>
              <nav className="sideNav">
                <SideMenu />
              </nav>
            </div>
            <div className="">
              <section className="main shadow-sm border-model">
                <div className="row mb-4">
                  <div className="col">
                    <h4 className="rest-info">Buffet Menu Creation</h4>
                  </div>
                  <div className="col text-end ">
                    <button
                      type="button"
                      onClick={() => closeCreation()}
                      className="btn-close "
                      aria-label="Close"
                    ></button>
                  </div>
                  <hr />
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <label className="form-label">
                      Buffet Name<span className="text-danger"> *</span>
                    </label>
                    <div>
                      <input
                        type="text"
                        value={name}
                        name="name"
                        onChange={(e: any) => handleChange(e)}
                        placeholder="Enter menu name"
                        className="form-control"
                      />
                      {errors.name && (
                        <span className="error-msg">{errors.name}</span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <label className="form-label">
                      {` Description : `}{" "}
                      <span className="text-danger"> *</span>
                    </label>
                    <textarea
                      className="form-control"
                      value={description}
                      name="description"
                      onChange={(e: any) => handleChange(e)}
                      rows={1}
                    ></textarea>
                    {errors.description && (
                      <span className="error-msg">{errors.description}</span>
                    )}
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col-md-4 col-sm-12">
                    <div className="row">
                      <label className="form-label">
                        Select Category (If you want to add more categories then select one by one
                          and select dish) 
                        {/* <span className="text-danger"> *</span> */}
                        
                      </label>
                      <div>
                        <select
                          className="form-control down-arrow"
                          value={categoryId}
                          onChange={(e: any) => handleChange(e)}
                          name="categoryId"
                        >
                          <option value="">Select</option>
                          {categoryListData.map((item, index) => (
                            <option key={index} value={item._id}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                        {/* {errors.categoryId && (
                          <span className="error-msg">{errors.categoryId}</span>
                        )} */}
                      </div>
                    </div>
                    <br />
                    <div className="row">
                      <label className="form-label">
                        Select Dishes 
                        {/* <span className="text-danger"> *</span> */}
                      </label>
                      <MultiSelect
                        options={dishOptions}
                        value={selected}
                        onChange={handleChange}
                        labelledBy="Select"
                      />
                      {/* {errors.selected && (
                        <span className="error-msg">{errors.selected}</span>
                      )} */}
                    </div>
                    <br />
                    <div className="row">
                      <label className="form-label">
                        Total Price ({defaultCurrency})<span className="text-danger"> *</span>
                      </label>
                      <div>
                        <input
                          type="number"
                          value={totalPrice}
                          name="totalPrice"
                          onChange={(e: any) => handleChange(e)}
                          placeholder="Enter Price"
                          className="form-control"
                        />
                        {errors.name && (
                          <span className="error-msg">{errors.name}</span>
                        )}
                      </div>
                    </div>
                    <br />
                    {/* <div className="col-md-6 mb-3">
                      <label htmlFor="UploadImg" className="form-label">Upload Images<span className="text-danger"> *</span></label>
                      <button
                        className="btn-action rounded-circle act-btn m-1"
                      // onClick={() => {
                      //   // viewDishDetails(item._id, "delete");
                      //   handleUploadShow(item._id);
                      // }}
                      >
                        <i className="bi bi-upload"></i>
                      </button>
                    </div> */}
                    <br />
                  </div>
                  <div className="col-md-8 col-sm-12">
                    <div className="opto-1">{renderInputs()}</div>
                  </div>
                </div>
              </section>
              <div className="text-center">
                <Button
                  style={{ background: "#ce1212", color: "white" }}
                  onClick={() => addOrUpdateMenu()}
                >
                  SUBMIT <i className="bx bx-chevron-right"></i>
                </Button>
              </div>
            </div>
          </div>
          {uploadImageModal && (
        <div
          className="modal"
          tabIndex={-1}
          style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Upload Buffet Menu Images
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleCloseUpload}
                ></button>
              </div>
              <section className="main shadow-sm">
                {isLoading ? (
                  <div className="text-center my-4">
                    <Spin tip="Uploading... Please wait" />
                  </div>
                ) : (
                  <>
                    <div className="row ">
                      <div className="text-center center">
                      <Upload
                          multiple={false} // Allow only one image to be uploaded
                          listType="picture-card" // Display images as thumbnails
                          beforeUpload={() => false} // Disable auto upload
                          fileList={imageList} // Use the imageList state
                          onChange={(info) => handleFileChange("images", info)} // Handle file change
                          onRemove={handleRemove} // Handle remove action
                        >
                          <Button
                            className="text-start"
                            style={{ width: "310px", height: "103px" }}
                            icon={<UploadOutlined />}
                          >
                            <b>Upload</b>
                            <span className="text-danger"> *</span>
                          </Button>
                        </Upload>
                      </div>
                      
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-bs-dismiss="modal"
                        onClick={handleCloseUpload}
                      >
                        Close
                      </button>
                      <button
                      onClick={handleUpload}
                        type="submit" className="btn btn-primary">
                        Upload
                      </button>
                      <ToastContainer />
                    </div>
                  </>
                )}
              </section>
            </div>
          </div>
        </div>
      )}
          {showStatusUpdateModal && (
            <div
              className="modal"
              tabIndex={-1}
              style={{
                display: "block",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              }}
            >
              <div className="modal-dialog modal-lg">
                <div className="modal-content ">
                  <div className="modal-header">
                    <h1 className="modal-title fs-5" id="exampleModalLabel">
                      Dish details
                    </h1>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={handleStatusUpdateClose}
                    ></button>
                  </div>
                  <table className="table table-bordered view-table infoTable">
                    <tbody>
                      <tr>
                        <th>Dish Name</th>
                        <td>{dishDetails?.name}</td>
                        <th>Base Production Cost[{defaultCurrency}]</th>
                        <td>{dishDetails?.productionCost}</td>
                      </tr>
                      <tr>
                        <th>No. of people who can consume </th>
                        <td>{dishDetails?.servingSize}</td>
                        <th>Description </th>
                        <td>{dishDetails?.description}</td>
                      </tr>
                      <tr>
                        <th>Avg. Preparation Time (in min.)</th>
                        <td>{dishDetails?.preparationTime}</td>
                        <th>Ingredients</th>
                        <td>
                          <td colSpan={3}>
                            <table className="sub-table">
                              <tbody>
                                <tr>
                                  <th>Name</th>
                                  <th>Quantity</th>
                                  <th>Unit</th>
                                  <th>Description </th>
                                </tr>
                                {dishDetails?.ingrediants.map(
                                  (ingredient: any) => (
                                    <tr key={ingredient._id}>
                                      <td>{ingredient.name}</td>
                                      <td>{ingredient.quantity}</td>
                                      <td>{ingredient.unit}</td>
                                      <td>{ingredient.description}</td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </td>
                        </td>
                      </tr>
                      <tr>
                        <th>Dish Tagging</th>
                        <td>
                          {dishDetails &&
                            dishDetails?.categoryTag != null &&
                            (dishDetails?.categoryTag as any).map(
                              (item, index) => (
                                <ul key={index}>
                                  <li>{item}</li>
                                </ul>
                              )
                            )}
                        </td>
                        <th>{/* Image */}</th>
                        <td>
                          {/* <img src='img/menu/menu-item-1.png' height={40} width={40} alt="dish-image" /> */}
                        </td>
                      </tr>
                      <tr>
                        <tr>
                          <th>DISH IMAGE</th>
                          <td>
                            <img
                              src={
                                dishDetails?.imageAttachement[0]?.url
                                  ? dishDetails?.imageAttachement[0]?.url
                                  : "/img/menu/menu-item-2.png"
                              }
                              className="menu-img1 img-fluid"
                              alt=""
                              width={610}
                              height={610}
                            />
                          </td>
                          <th></th>
                          <td></td>
                        </tr>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      <ToastContainer />
    </>
  );
}

export default BuffetMenuCreation;
