import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "../home";
import RegistrationRestaurants from "../registration/index";
import Dishlist from "../dishesManagement/dishlist";
import OurPlatform from "../ourPlatform";
import LoginPage from "../login";
import RestaurantView from "../restaurant";
import Sidebar from "../sidebar";
import Menu from "../menu";
import RestaurantList from "../restaurant-list";
import Dish from "../dish";
import Profile from "../Profile";
import Theme from "../Theme";
import RestaurantTheme from "../RestaurantTheme";
import Category from "../Category";
import BranchMenu from "../BranchMenu";
import Branches from "../Branches";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store/store";
import MenuCreation from "../MenuCreation";
import ForgotPassword from "../forgot-password";
import ResetPassword from "../reset-password";

// import CreatePassword from "../create-new-password";
import RestaurantInfo from "../restaurantInfo";
import DocumentUpload from "../document-upload";
import MenuView from "../MenuCreation/MenuView";
import UsertList from "../User-List";

import TodaySpecialDish from "../TodaysSpecial";
import RestaurantSpecialDish from "../RestaurantSpecial";
import EventMeal from "../EventMeal";
import BuffetMeal from "../BuffetMeal";
import HomePage from "../HomePage";
import SubscriptionPlan from "../Subscription";
import ChangePassword from "../ChangePassword";

import DiscountedOffer from "../TodayDiscountedOffer";
import ComboOffer from "../TodayComboOffer";
import RestaurantMenuView from "../RestaurantMenuView";
import MenuDealzHomePage from "../MenuDealzHomePage/MenuDealz";
import ContactUs from "../ContactUs/ContactUs";
import BuffetMenuCreation from "../BuffetMenuCreation";


export default function MyRouter() {
  const isAuthenticated = useSelector(
    (state: RootState) => state.user.isAuthenticated
  );

  return (
    <div>
      <Routes>
        <Route path="/" element={<MenuDealzHomePage />} />
        <Route path="/register" element={<RegistrationRestaurants />} />
        <Route path="/welcome" element={<OurPlatform />} />
        <Route
          path="/dishlist"
          element={isAuthenticated ? <Dishlist /> : <Navigate to="/login" />}
        />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/restaurant" element={<RestaurantView />} />
        <Route path="/restaurant-menu"
         element={isAuthenticated ? <RestaurantMenuView /> : <Navigate to="/login"  />}
         />
        <Route
          path="/sidebar"
          element={isAuthenticated ? <Sidebar /> : <Navigate to="/login" />}
        />
        <Route
          path="/users"
          element={
            isAuthenticated ? <UsertList /> : <Navigate to="/login" />
          }
        />
        <Route
            path="/subscription-plan"
            element={
              isAuthenticated ? <SubscriptionPlan /> : <Navigate to="/login" />
            }
          />
        <Route
          path="/menu"
          element={isAuthenticated ? <Menu /> : <Navigate to="/login" />}
        />
        <Route
          path="/dish"
          element={isAuthenticated ? <Dish /> : <Navigate to="/login" />}
        />
        <Route
          path="/restaurants"
          element={
            isAuthenticated ? <RestaurantList /> : <Navigate to="/login" />
          }
        />
        <Route
          path="/restaurants-info"
          element={
            isAuthenticated ? <RestaurantInfo /> : <Navigate to="/login" />
          }
        />
        <Route
          path="/profile"
          element={isAuthenticated ? <Profile /> : <Navigate to="/login" />}
        />
        <Route
          path="/theme"
          element={isAuthenticated ? <Theme /> : <Navigate to="/login" />}
        />
        <Route
          path="/restaurant-theme"
          element={
            isAuthenticated ? <RestaurantTheme /> : <Navigate to="/login" />
          }
        />
        <Route
          path="/category"
          element={isAuthenticated ? <Category /> : <Navigate to="/login" />}
        />
        <Route
          path="/branch-menu"
          element={isAuthenticated ? <BranchMenu /> : <Navigate to="/login" />}
        />
        <Route
          path="/branches"
          element={isAuthenticated ? <Branches /> : <Navigate to="/login" />}
        />

        <Route
          path="/menu-creation"
          element={
            isAuthenticated ? <MenuCreation /> : <Navigate to="/login" />
          }
        />

        {/* <Route
          path="/menu-creation/:menuId"
          element={
            isAuthenticated ? <MenuCreation /> : <Navigate to="/login" />
          }
        /> */}
        <Route
          path="/buffet-menu-creation"
          element={
            isAuthenticated ? <BuffetMenuCreation /> : <Navigate to="/login" />
          }
        />
        {/* <Route
          path="/buffet-menu-creation/:menuId"
          element={
            isAuthenticated ? <BuffetMenuCreation /> : <Navigate to="/login" />
          }
        /> */}

        {/* <Route
          path="/menu-view/:menuId"
          element={
            isAuthenticated ? <MenuView /> : <Navigate to="/login" />
          }
        /> */}
        {/* <Route
          path="/buffet-menu-view/:menuId"
          element={
            isAuthenticated ? <BuffetMenuView /> : <Navigate to="/login" />
          }
        /> */}
        <Route
          path="/restaurant/:menuId/:branchId/:restaurantId/:type"
          element={<RestaurantView />}
        />
        <Route
          path="/restaurant-menu/:branchMenuId/:branchId/:restaurantId"
          element={<RestaurantMenuView />}
        />
        <Route
          path="/document-upload"
          element={
            isAuthenticated ? <DocumentUpload /> : <Navigate to="/login" />
          }
        />
        <Route
          path="/today-combo-offers"
          element={isAuthenticated ? <ComboOffer /> : <Navigate to="/login" />}
        />
        <Route
          path="/today-discounted-offers"
          element={
            isAuthenticated ? <DiscountedOffer /> : <Navigate to="/login" />
          }
        />

        <Route
          path="/todays-special"
          element={
            isAuthenticated ? <TodaySpecialDish /> : <Navigate to="/login" />
          }
        />
        <Route
          path="/restaurant-special"
          element={
            isAuthenticated ? <RestaurantSpecialDish /> : <Navigate to="/login" />
          }
        />
        <Route
          path="/buffet-meal"
          element={
            isAuthenticated ? <BuffetMeal /> : <Navigate to="/login" />
          }
        />
        <Route
          path="/event-meal"
          element={
            isAuthenticated ? <EventMeal /> : <Navigate to="/login" />
          }
        />
        <Route path="/customer-home-page"
          element={<HomePage />} />
        {/* <Route path="/home-page"
          element={<MenuDealzHomePage />} /> */}
        <Route path="/contact-us"
          element={<ContactUs />} />
        <Route path="/subscription-plan"
          element={<SubscriptionPlan />} />

        <Route path="/change-password"
          element={<ChangePassword />} />
          
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:authCode" element={<ResetPassword />} />
        {/* <Route path="/forgot-password" element={<ForgotPassword />} /> */}
        {/* <Route path="/create-password" element={<CreatePassword />} /> */}
      </Routes>
    </div>
  );
}
