import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../redux/authGuard/userAuthenticate";
import { toast } from "react-toastify";
import api from "../../api";

interface SideBarHeaderProps {
  showNav: boolean;
  setShowNav: React.Dispatch<React.SetStateAction<boolean>>;
}

const LoggedInHeader: React.FC<SideBarHeaderProps> = ({
  showNav,
  setShowNav,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const [userData, setUserData] = useState(null);
  const [userName, setUserName] = useState("");
  const [logoUrl, setLogoUrl] = useState<string | null>(null);
  const [restaurantId, setRestaurantId] = useState("");
  useEffect(() => {
    const loggedInUserData = localStorage.getItem("loggedInUser");
    if (loggedInUserData) {
      const parsedData = JSON.parse(loggedInUserData);
      setRestaurantId(parsedData.restaurantId);
    }
  }, []);
  useEffect(() => {
    console.log("rest idd 111 " + restaurantId);
    if (restaurantId) {
      console.log("rest idd 222 " + restaurantId);
      fetchRestaurantDetailData(restaurantId);
    }
  }, [restaurantId]);
  const fetchRestaurantDetailData = async (id: string) => {
    try {
      const response = await api.get(`/api/identity/restaurant/${id}`);
      const restaurantDetailData = response.data.data.attachement;

      // Find the logo URL from the attachment data
      const logoData = restaurantDetailData.find(
        (item: any) => item.typeCode === "logo"
      );
      console.log("****++");
      console.log(logoData);

      if (logoData && logoData.url) {
        console.log("Logo URL:", logoData.url);

        setLogoUrl(logoData.url);
      } else {
        console.log("Logo not found");
        setLogoUrl("https://cdn-icons-png.freepik.com/512/219/219986.png?uid=R150845636&ga=GA1.2.650367016.1716989375");
        
      }
    } catch (error) {
      console.error("Error fetching restaurant detail data:", error);
      return null;
    }
  };
  useEffect(() => {
    const loggedInUserData = localStorage.getItem("loggedInUser");
    if (loggedInUserData) {
      // setUserData(JSON.parse(loggedInUserData));
      const parsedData = JSON.parse(loggedInUserData);
      setUserName(parsedData.userName);
      // console.log("resttt data "+parsedData);
      // console.log("resttt iddd "+parsedData.restaurantId);
      // console.log("logged restaurant iddd " + userName);
    }
  }, []);

  const logOut = async () => {
    let payload = {};
    dispatch(logout());

    try {
      await api
        .post("api/identity/logout", payload)
        .then(function (response) {
          console.log("sucess respp");
          toast.success("Logout Successfull!");
          navigate("/login");
          console.log(response.data);
          return response.data;
        })
        .catch(function (error) {
          console.log("Show error notification!");
          toast.error("Error in api call!");
          console.log(error);
          return Promise.reject(error);
        });
    } catch (error) {
      console.error("There was an error!", error);
    }
  };

  const changePassword = () => {
    navigate("/change-password");
  };
  return (
    <>
      <header className={`header${showNav ? " body-pd" : ""}`}>
        <div className="header_toggle">
          <i
            className={`bi ${showNav ? "bi-list" : "bi-list"}`}
            onClick={() => setShowNav(!showNav)}
          />
        </div>

        <div className="dropdown">
          <a
            href="#"
            className="d-flex align-items-center text-decoration-none dropdown-toggle"
            id="dropdownUser1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {logoUrl ? (
              <img
                src={logoUrl}
                alt="logo"
                style={{ objectFit: "cover", height: "40px" }}
              />
            ) : (
              // <img
              //   src="https://cdn-icons-png.freepik.com/512/219/219986.png?uid=R150845636&ga=GA1.2.650367016.1716989375"
              //   alt="hugenerd"
              //   width="40"
              //   height="40"
              //   className="rounded-circle"
              // />
              <></>
            )}

            <span className="d-none d-sm-inline mx-1">{userName}</span>
          </a>
          <ul className="dropdown-menu dropdown-menu-dark text-small shadow">
            {/* <li>
              <a className="dropdown-item" href="#">
                Settings
              </a>
            </li> */}
            <li className="w-100 px-2">
              {/* <Link
                style={{ textDecoration: "none",cursor: "pointer" }}
                className="nav-link px-0 align-middle text-white "
                to="/profile"
              >
                {" "}
                <i className="bi bi-profile-app-fill p-1"></i>Profile
              </Link> */}
            </li>
            <li>{/* <hr className="dropdown-divider" /> */}</li>
            <li>
              <a
                style={{ cursor: "pointer", color:"black" }}
                className="dropdown-item"
                onClick={changePassword}
              >
                Change Password
              </a>
            </li>
            <li>
              <a
                style={{ cursor: "pointer", color:"black" }}
                className="dropdown-item"
                onClick={logOut}
              >
                Sign out
              </a>
            </li>
          </ul>
        </div>
      </header>
    </>
  );
};

export default LoggedInHeader;
