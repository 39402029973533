import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./side.css";
// import { HeaderSection } from "../../constants/constant";
function SideMenu() {
  const [activeMenu, setActiveMenu] = useState("");
  const [activeOfferMenu, setActiveOfferMenu] = useState("");
  const location = useLocation();
  const [isActive, setIsActive] = useState(false);
  const [isOfferActive, setIsOfferActive] = useState(false);

  useEffect(() => {
    setActiveMenu(location.pathname);
    // Set activeOfferMenu for both combo and discounted offers
    if (
      ["/today-combo-offers", "/today-discounted-offers"].includes(
        location.pathname
      )
    ) {
      setActiveOfferMenu("special-offers");
    } else {
      setActiveOfferMenu(location.pathname);
    }
    // Keep the dropdown open if the current route is one of the submenu routes
    if (
      ["/category", "/menu", "/buffet-meal", "/dish", "/branch-menu"].includes(
        location.pathname
      )
    ) {
      setIsActive(true);
    } else if (
      [
        "/today-combo-offers",
        "/today-discounted-offers",
        "/todays-special",
        "/restaurant-special",
        "/event-meal",
        "/upcoming-offers",
      ].includes(location.pathname)
    ) {
      setIsOfferActive(true);
    } else {
      setIsActive(false);
      setIsOfferActive(false);
    }
  }, [location.pathname]);

  // Function to toggle dropdown
  const toggleDropdown = () => {
    setIsActive(!isActive); // Toggle the active state
    setIsOfferActive(false);
  };

  const toggleOfferDropdown = () => {
    setIsOfferActive(!isOfferActive); // Toggle the active state
    setIsActive(false);
  };

  return (
    <div>
      <div>
        <a className="nav_logo" rel="noopener">
          {/* <Link className="nav_link " to="/"> */}
          {/* {HeaderSection.MENUDEALZ} */}
          <img
            src="/img/Menu Dealz Logo-01.jpg"
            alt="logo"
            style={{ objectFit: "cover", height: "40px" }}
          />
          {/* </Link> */}
        </a>
        <div className="overflow-y-auto">
          <div className="nav_list">
            <Link
              rel="noopener"
              className={`nav_link ${
                activeMenu === "/sidebar" ? "activeMenu" : ""
              }`}
              onClick={() => setActiveMenu("/sidebar")}
              to="/sidebar"
            >
              {" "}
              <i className="bi bi-speedometer pro"></i> Dashboard{" "}
            </Link>

            {/* <Link className={`nav_link ${activeMenu === "/category"? "activeMenu":""}`} onClick={() => setActiveMenu("/category")} to="/category"> <i className="bi bi-person-fill pb-2 pr-1 pro"></i>Category</Link> */}
            {/* <Link
            className={`nav_link ${
              activeMenu === "/restaurants" ? "activeMenu" : ""
            }`}
            onClick={() => setActiveMenu("/restaurants")}
            to="/restaurants"
          >
            {" "}
            <i className="bi bi-list-task pb-2 pr-1 pro"></i> Restaurants
          </Link> */}
            <Link
              className={`nav_link ${
                activeMenu === "/restaurants-info" ? "activeMenu" : ""
              }`}
              onClick={() => setActiveMenu("/restaurants-info")}
              to="/restaurants-info"
            >
              {" "}
              <i className="bi bi-buildings-fill pro"></i> Restaurants Info
            </Link>
            <Link
              className={`nav_link ${
                activeMenu === "/branches" ? "activeMenu" : ""
              }`}
              onClick={() => setActiveMenu("/branches")}
              to="/branches"
            >
              {" "}
              <i className="bi bi-diagram-3-fill pb-2 pr-1 pro"></i>Branches
            </Link>

            {/* Dropdown button */}
            <button
              className={`nav_link dropdown-btn menu-mgmt  ${
                isActive ? "active" : ""
              }`}
              onClick={toggleDropdown}
            >
              <i className="bi bi-menu-app-fill pb-2 pr-1 pro"></i> Menu
              Management
              <i className="bi bi-chevron-down"></i>
            </button>

            {/* Dropdown content */}
            <div
              className="dropdown-content"
              style={{ display: isActive ? "block" : "none" }}
            >
              {/* Dropdown content items go here */}
              <Link
                className={`dropdown-item nav_link ${
                  activeMenu === "/dish" ? "activeMenu" : ""
                }`}
                onClick={() => setActiveMenu("/dish")}
                to="/dish"
              >
                {" "}
                <i className="bi bi-disc pb-2 pr-1 pro sub-cat"></i>
                Dish
              </Link>
              <Link
                className={`dropdown-item nav_link ${
                  activeMenu === "/category" ? "activeMenu" : ""
                }`}
                onClick={() => setActiveMenu("/category")}
                to="/category"
              >
                {" "}
                <i className="bi bi-bar-chart-steps pb-2 pr-1 pro sub-cat"></i>
                Menu Category
              </Link>
              
              <Link
                className={`dropdown-item nav_link ${
                  activeMenu === "/menu" ? "activeMenu" : ""
                }`}
                onClick={() => setActiveMenu("/menu")}
                to="/menu"
              >
                {" "}
                <i className="bi bi-book pb-2  pr-1 pro sub-cat"></i> Regular Menu
              </Link>
              <Link
                className={`dropdown-item nav_link ${
                  activeMenu === "/buffet-meal" ? "activeMenu" : ""
                }`}
                onClick={() => setActiveMenu("/buffet-meal")}
                to="/buffet-meal"
              >
                {" "}
                <i className="bi bi-disc pb-2 pr-1 pro sub-cat"></i> Buffet Menu
              </Link>
              <Link
                className={`dropdown-item nav_link ${
                  activeMenu === "/branch-menu" ? "activeMenu" : ""
                }`}
                onClick={() => setActiveMenu("/branch-menu")}
                to="/branch-menu"
              >
                {" "}
                <i className="bi bi-journal-bookmark-fill pb-2 pr-1 pro sub-cat"></i> Menu Assignment
              </Link>

              {/* <Link
                className={`dropdown-item nav_link ${activeMenu === "/branch-menu" ? "activeMenu" : ""
                  }`}
                onClick={() => setActiveMenu("/branch-menu")}
                to="/branch-menu"
              >
                {" "}
                <i className="bi bi-menu-app-fill pb-2  pr-1 pro sub-cat"></i>{" "}
                Branch Menu
              </Link> */}
            </div>

            <button
              className={`nav_link dropdown-btn menu-mgmt  ${
                isOfferActive ? "active" : ""
              }`}
              onClick={toggleOfferDropdown}
            >
              <i className="bi bi-gift pb-2 pr-1 pro"></i> Offers
              <i className="bi bi-chevron-down"></i>
            </button>
            <div
              className="dropdown-content"
              style={{ display: isOfferActive ? "block" : "none" }}
            >
              <Link
                className={`dropdown-item nav_link ${
                  activeOfferMenu === "special-offers" ? "activeMenu" : ""
                }`}
                onClick={() => setActiveOfferMenu("special-offers")}
                to="/today-combo-offers"
              >
                <i className="bi bi-percent pb-2 pr-1 pro sub-cat"></i>
                Special Offers
              </Link>

              {/* <Link
                className={`dropdown-item nav_link ${activeOfferMenu === "/upcoming-offers" ? "activeMenu" : ""
                  }`}
                onClick={() => setActiveOfferMenu("/upcoming-offers")}
                to="/upcoming-offers"
              >
                {" "}
                <i className="bi bi-calendar-week  pb-2 pr-1 pro sub-cat"></i>
                Upcoming Special<br />Offers
              </Link> */}
              <Link
                className={`dropdown-item nav_link ${
                  activeOfferMenu === "/todays-special" ? "activeMenu" : ""
                }`}
                onClick={() => setActiveOfferMenu("/todays-special")}
                to="/todays-special"
              >
                {" "}
                <i className="bi bi-calendar-check pb-2 pr-1 pro sub-cat"></i>
                Today's Special
              </Link>
              {/* <Link
                className={`dropdown-item nav_link ${activeOfferMenu === "/restaurant-special" ? "activeMenu" : ""
                  }`}
                onClick={() => setActiveOfferMenu("/restaurant-special")}
                to="/restaurant-special"
              >
                {" "}
                <i className="bi bi-journal-bookmark-fill pb-2 pr-1 pro sub-cat"></i>
                Restaurant/Chef's<br /> Special
              </Link> */}

              <Link
                className={`dropdown-item nav_link ${
                  activeOfferMenu === "/event-meal" ? "activeMenu" : ""
                }`}
                onClick={() => setActiveOfferMenu("/event-meal")}
                to="/event-meal"
              >
                {" "}
                <i className="bi bi-cake2-fill pb-2 pr-1 pro sub-cat"></i>
                Special Event Meal
              </Link>
              {/* <Link
                  className={`dropdown-item nav_link ${activeOfferMenu === "subscription-info" ? "activeMenu" : ""}`}
                  onClick={() => setActiveOfferMenu("subscription-info")}
                  to="/subscription-info"
              >
                  <i className="bi bi-percent pb-2 pr-1 pro sub-cat"></i>
                  Subscription
              </Link> */}
            </div>

            {/* <Link className={`nav_link ${activeMenu === "/menu"? "activeMenu":""}`} onClick={() => setActiveMenu("/menu")} to="/menu">  <i className="bi bi-menu-app-fill pb-2  pr-1 pro"></i> Menu</Link> */}

            {/* <Link
            className={`nav_link ${activeMenu === "/branch-menu" ? "activeMenu" : ""
              }`}
            onClick={() => setActiveMenu("/branch-menu")}
            to="/branch-menu"
          >
            {" "}
            <i className="bi bi-disc-fill pb-2 pr-1 pro"></i>Branch Menu
          </Link> */}

            {/* <Link
              className={`nav_link ${
                activeMenu === "/document-upload" ? "activeMenu" : ""
              }`}
              onClick={() => setActiveMenu("/document-upload")}
              to="/document-upload"
            >
              {" "}
              <i className="bi bi-upload pb-2 pr-1 pro"></i>Document Center
            </Link> */}
            <Link
              className={`nav_link ${
                activeMenu === "/users" ? "activeMenu" : ""
              }`}
              onClick={() => setActiveMenu("/users")}
              to="/users"
            >
              {" "}
              <i className="bi bi-person-circle  pro"></i> User Management
            </Link>
            <Link
              className={`nav_link ${
                activeMenu === "/subscription-plan" ? "activeMenu" : ""
              }`}
              onClick={() => setActiveMenu("/subscription-plan")}
              to="/subscription-plan"
            >
              {" "}
              <i className="bi bi-bookmarks-fill pro"></i> Subscription Plans
            </Link>

            {/* <Link
            className={`nav_link ${
              activeMenu === "/theme" ? "activeMenu" : ""
            }`}
            onClick={() => setActiveMenu("/theme")}
            to="/theme"
          >
            {" "}
            <i className="bi bi-gear pb-2 pr-1 pro"></i>A Theme Setting
          </Link> */}

            {/* <Link
            className={`nav_link ${
              activeMenu === "/restaurant-theme" ? "activeMenu" : ""
            }`}
            onClick={() => setActiveMenu("/restaurant-theme")}
            to="/restaurant-theme"
          >
            {" "}
            <i className="bi bi-gear pb-2 pr-1 pro"></i>R Theme Setting
          </Link> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SideMenu;
