import React, { useEffect } from "react";
import "./MenuDealz.css"; // Assuming custom styles are in this CSS file
import MainImg from "./Img/MenuPhoneView.png";
import flashImg from "./Img/Flash deals Img.png";
import DigitalImg from "./Img/Digital Menus Img.png";
import QrImg from "./Img/QR code menu Img.png";
import UpcomingImg from "./Img/Upcoming Offers Img.png";
import { Link } from "react-router-dom";

const MenuDealzHomePage = () => {
  useEffect(() => {
    // Scroll to top when the component mounts
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const boxes = document.querySelectorAll(".menu-update-container");

    const checkBoxes = () => {
      const triggerBottom = (window.innerHeight / 5) * 4;

      boxes.forEach((box) => {
        const boxTop = box.getBoundingClientRect().top;

        if (boxTop < triggerBottom) {
          box.classList.add("show");
        } else {
          box.classList.remove("show");
        }
      });
    };

    window.addEventListener("scroll", checkBoxes);
    checkBoxes(); // Initial check for already visible boxes

    return () => {
      window.removeEventListener("scroll", checkBoxes);
    };
  }, []);
  return (
    <div>
      <div className="qrmenu-container">
        <div className="qrmenu-content">
          <h1 className="qrmenu-heading">
            Digitize your Restaurant Menu - Simple, Smart, Seamless.
          </h1>
          <p className="qrmenu-description">
            Create your menu instantly. Manage your offers
          </p>
          <ul className="qrmenu-features">
            <li>✅ Digital Menus</li>
            <li>✅ Flash Deals</li>
            <li>✅ Upcoming Offers</li>
            <li>✅ QR code Menu</li>
          </ul>
          <div className="qrmenu-buttons">
            <Link to="/register" className="view-demo-btn">
              Register now
            </Link>
            {/* <button className="create-menu-btn">Register now</button> */}
            <button className="view-demo-btn">View demo</button>
          </div>
        </div>
        <div className="qrmenu-image">
          <img src={MainImg} alt="QR Menu Demo" className="qrmenu-demo-img" />
          <img src={QrImg} alt="Menu updates" className="qrmenu-demo-img" />
        </div>
      </div>
      <div className="how-it-works-container">
        <h2 className="how-it-works-heading">How it works?</h2>
        <div className="how-it-works-steps">
          {/* Step 1 */}
          <div className="how-it-works-step">
            <div className="how-it-works-icon">
              <img
                src="https://img.icons8.com/ios-filled/50/FFAA33/user.png"
                alt="Create Account"
              />
            </div>
            <h3>1. Create account</h3>
            <p>
              Please click the “Register button”. You'll need to provide basic
              information about your restaurant, such as its name,contact
              details.
            </p>
          </div>

          {/* Step 2 */}
          <div className="how-it-works-step">
            <div className="how-it-works-icon">
              <img
                src="https://img.icons8.com/ios-filled/50/FFAA33/add.png"
                alt="Setup Menu"
              />
            </div>
            <h3 style={{ fontFamily: "Poppins, sans-serif" }}>
              2. Create menu
            </h3>
            <p>
              Create your Menu Easily add your menu, Your,special deals, Offers
              ,todays specials.Upload high-quality photos of your dishes to
              showcase your offerings to potential customers.
            </p>
          </div>

          {/* Step 3 */}
          <div className="how-it-works-step">
            <div className="how-it-works-icon">
              <img
                src="https://img.icons8.com/ios-filled/50/FFAA33/qr-code.png"
                alt="Download QR Code"
              />
            </div>
            <h3>3. Download QR code</h3>
            <p>
              Download and print QR code. No need to reprint the qr code after
              editing or deleting items
            </p>
          </div>
        </div>
      </div>
      <div className="menu-update-container">
        <div className="menu-update-content">
          <div className="menu-update-text">
            <h4 className="menu-update-title">Digital Menus</h4>
            <p className="menu-update-description">
              Menu and quality pictures will be shown to the customers
            </p>
          </div>
          <div className="menu-update-image">
            <img
              src={DigitalImg}
              alt="Menu updates"
              className="menu-update-laptop-image"
            />
          </div>
        </div>
      </div>
      <div className="menu-update-container">
        <div className="menu-update-content">
          <div className="menu-update-text">
            <h4 className="menu-update-title">Flash deals</h4>
            <p className="menu-update-description">
              Quick and instant deals will be shown here even if its a discounts
              deal or a combo deal.
            </p>
          </div>
          <div className="menu-update-image">
            <img
              src={flashImg}
              alt="Menu updates"
              className="menu-update-laptop-image"
            />
          </div>
        </div>
      </div>
      <div className="menu-update-container">
        <div className="menu-update-content">
          <div className="menu-update-text">
            <h4 className="menu-update-title">Upcoming Offers</h4>
            <p className="menu-update-description">
              Make your customer aware of your upcoming offers , weekend offers
              and event deals
            </p>
          </div>
          <div className="menu-update-image">
            <img
              src={UpcomingImg}
              alt="Menu updates"
              className="menu-update-laptop-image"
            />
          </div>
        </div>
      </div>
      <div className="menu-update-container">
        <div className="menu-update-content">
          <div className="menu-update-text">
            <h4 className="menu-update-title">QR code menu</h4>
            <p className="menu-update-description">
              Restaurants have complete control over the menu, You can edit,
              add, delete suspend menu dishes. No need to reprint the QR code
            </p>
          </div>
          <div className="menu-update-image">
            <img
              src={QrImg}
              alt="Menu updates"
              className="menu-update-laptop-image"
            />
          </div>
        </div>
      </div>
      <div className="banner-container">
        <div className="banner-card">
          <h1 className="banner-title">Ready to create digital QR menu?</h1>
          <br />
          {/* <button className="banner-button">Register now</button> */}
          <Link to="/register" className="banner-button">
            Register now
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MenuDealzHomePage;
