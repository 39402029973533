import React, { useEffect, useState } from "react";
import Select from "react-select";
import SideMenu from "../sidemenu";
import "./index.scss";
// import { SidebarSection } from "../../constants/constant";
import SideBarHeader from "../header/LoggedInHeader";
import api from "../../api";
import DishRegister from "./registerDish";
import Loader from "../Loader";
import Pagination from "./pagination";
import { DishDetailsInterface, DishInterface } from "./interface";
import DishDetailView from "./dishDetailView";
import { ToastContainer, toast } from "react-toastify";
import { Button, Upload, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { MultiSelect } from "react-multi-select-component";
interface dishOption {
  value: string;
  label: string;
  code: string;
  description: string;
  price: number;
}
interface Item {
  _id: string;
  name: string;
  code: string;
  description: string;
  price: number;
}

interface Option {
  value: string;
  label: string;
  code: string;
  isActive: boolean;
}

function TodaySpecialDish() {
  // const [count, setCount] = useState(1);

  const [showNav, setShowNav] = useState(true);
  const [loading, setLoading] = useState(true);
  // const [error, setError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  // const [searchText, setSearchText] = useState("");
  const [dishData, setDishData] = useState<DishInterface[]>([]);
  const [dishDetailData, setDishDetailData] =
    useState<DishDetailsInterface | null>(null);
  const [dishId, setDishId] = useState<string | null>(null);
  // const [actionType, setActionType] = useState<string | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showStatusUpdateModal, setShowStatusUpdateModal] = useState(false);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [dishStatus, setDishStatus] = useState("");
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [uploadImageModal, setUploadImageModal] = useState(false);
  const [defaultCurrency, setDefaultCurrency] = useState("");

  const [selectedDish, setSelectedDish] = useState<string>("");
  const [dishOptions, setDishOptions] = useState<dishOption[]>([]);
  const [restaurantId, setRestaurantId] = useState(null);
  const [selected, setSelected] = useState<Option[]>([]);
  const ITEMS_PER_PAGE = 10;
  // update dis state
  const keys = {
    images: "images",
    videos: "videos",
  };
  const [formData, setFormData] = useState({
    selectedDishId: "",
    price: 0,
    startDate: "",
    endDate: "",
    assignedBranch: [
     
    ],
  });
  useEffect(() => {
    // Set initial form data when dishDetailData is available

    if (dishDetailData) {
      const firstBranch = dishDetailData.assignedBranch && dishDetailData.assignedBranch[0];

        const formatDateTime = (dateString: string) => {
            if (!dateString) return "";
            const date = new Date(dateString);
            return date.toISOString().slice(0, 16); // Format to "YYYY-MM-DDTHH:MM"
        };

        const transformedOptions: Option[] = dishDetailData.assignedBranch.map(branch => {
            // Find the corresponding branch option by branchId
            const correspondingBranchOption = branchOptions.find(option => option.value === branch.branchId);

            return {
                value: branch.branchId,
                label: correspondingBranchOption ? correspondingBranchOption.label : branch.branchId, // Use the label from branchOptions or fallback to branchId
                code: correspondingBranchOption ? correspondingBranchOption.code : branch.branchId, // Use the code from branchOptions or fallback to branchId
                isActive: branch.isActive // Set the active status from dishDetailData
            };
        });

        setSelected(transformedOptions);
      setFormData({
        price: dishDetailData.price,
        selectedDishId: dishDetailData.dish._id,

        startDate: formatDateTime(firstBranch ? firstBranch.startDate : ""),
            endDate: formatDateTime(firstBranch ? firstBranch.endDate : ""),
            assignedBranch: dishDetailData.assignedBranch.map((branch) => ({
                branchId: branch.branchId,
            })),
      });
    }
  }, [dishDetailData]);

  const [imageList, setImageList] = useState([]);
  const [videoList, setVideoList] = useState([]);

  const props = (key: any) => ({
    name: key,
    //action:process.env.REACT_APP_API_URL + "api/utility/dish/" + dishId,
    action: "/api/utility/dish/" + dishId,
    method: "PUT",
    headers: {
      authorization: "authorization-text",
    },
    multiple: true,
    onChange(info: any) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} File uploaded successfully`);
      } else if (status === "error") {
        message.error(`${info.file.name} File upload failed.`);
      }

      if (key === "images") {
        setImageList(info.fileList); // Update imageList state
      } else if (key === "videos") {
        setVideoList(info.fileList); // Update videoList state
      }
    },
  });

  const handleUpload = async () => {
    setLoading(true);
    try {
      const formData = new FormData();

      // Add images to formData
      imageList.forEach((image) => {
        formData.append("images", image.originFileObj);
      });

      // Add videos to formData
      videoList.forEach((video) => {
        formData.append("videos", video.originFileObj);
      });

      const response = await fetch(`/api/utility/dish/${dishId}`, {
        method: "PUT",
        headers: {
          authorization: "authorization-text",
        },
        body: formData,
      });

      if (response.ok) {
        setLoading(false);
        message.success("Files uploaded successfully");
        handleCloseUpload();
      } else {
        message.error("File upload failed");
      }
    } catch (error) {
      console.error("Error uploading files:", error);
      message.error("File upload failed");
    }
  };

  const handleClose = () => {
    setShowModal(false);
    setDishId(null);
  };
  const handleCloseUpload = () => {
    setUploadImageModal(false);
  };
  const handleShow = (itemId: string | null) => {
    setShowModal(true);
    setDishId(itemId!);
  };
  const handleDeleteClose = () => {
    setShowDeleteModal(false);
    setDishId(null);
  };
  const handleDeleteShow = (itemId: string) => {
    setShowDeleteModal(true);
    setDishId(itemId!);
  };
  const handleUploadShow = (itemId: string) => {
    setUploadImageModal(true);
    setDishId(itemId!);
  };
  const handleRegisterClose = () => {
    //dishListFetchData(currentPage);
    setShowRegisterModal(false);
  };
  const handleRegisterShow = () => {
    setShowRegisterModal(true);
    // setPublishModalOpen(false);
  };
  const handleUpdateClose = () => {
    setShowUpdateModal(false);
    setDishId(null);
  };
  const handleUpdateShow = (itemId: string) => {
    setUpdatePublishModalOpen(false);
    setShowUpdateModal(true);
    setDishId(itemId!);
  };

  const handleStatusUpdateClose = () => setShowStatusUpdateModal(false);
  const handleStatusUpdateShow = (itemId: string) => {
    setShowStatusUpdateModal(true);
    setDishId(itemId!);
  };
  const updateStatus = async () => {
    setLoading(true);
    console.log(" update status ");
    try {
      await api
        .put(
          "/api/restaurant/today-special/status/" +
            dishId +
            "?statusCode=" +
            dishStatus
        )
        .then(function (response) {
          message.success("Status updated Successfully!");
          setLoading(false);
          console.log(response.data);
          handleStatusUpdateClose();
          setDishId(null);
          dishListFetchData(currentPage);
          return response.data;
        })
        .catch(function (error) {
          console.log("Show error notification!");
          message.error("Error in api call!");
          setLoading(false);
          console.log(error);
          return Promise.reject(error);
        });
    } catch (error) {
      console.error("There was an error!", error);
    }
  };

  const dishListFetchData = async (page: number) => {
    try {
      console.log("fetch detailss");
      setLoading(true);
      const response = await api.get(
        `/api/restaurant/today-special/?page=${page}&limit=${ITEMS_PER_PAGE}`
      );
      console.log(response.data.data.todaySpecial);
      const dishData = response.data;
      const totalCount = dishData.data.totalCount;
      // console.log("dishData", dishData);
      setDishData(dishData.data.todaySpecial || []);
      setTotalPages(Math.ceil(totalCount / ITEMS_PER_PAGE));
      // setError(
      //   dishData.length > 0
      //     ? null
      //     : "No results found for the given search text."
      // );
    } catch (error: any) {
      console.error("Error fetching data:", error);
      // setError("An unexpected error occurred. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    dishListFetchData(currentPage);
  }, [currentPage]);

  const dishDetailFetchData = async (id: string | null) => {
    try {
      setLoading(true);
      if (id !== null) {
        const response = await api.get(`/api/restaurant/today-special/${id}`);

        const dishDetailData = response.data;
        console.log(dishDetailData.data);
        setDishDetailData(dishDetailData.data);
        // console.log("dishDetailData", dishDetailData.data);
      }
    } catch (error: any) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    dishDetailFetchData(dishId);
  }, [dishId]);

  const handleDelete = async () => {
    try {
      if (dishId !== null) {
        setLoading(true);
        const response = await api.delete(`/api/restaurant/today-special/${dishId}`);
        setDishId(null);
        await dishListFetchData(currentPage);
        handleDeleteClose();
        message.success(response.data.message)
      }
    } catch (error) {
      console.error("Error deleting restaurant:", error);
      message.error("Can't proceed with deletion, today special is attached to active branches")
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: name === "price" ? parseInt(value, 10) : value,
    });
  };

  useEffect(() => {
    const loggedInUserData = localStorage.getItem("loggedInUser");
    if (loggedInUserData) {
      const parsedData = JSON.parse(loggedInUserData);
      setDefaultCurrency(parsedData.restaurantdefaultCurrencyCode);
    }
  }, []);
  useEffect(() => {
    setTimeout(() => setLoading(false), 3300);
    getAllActiveDishes();
  }, []);
  useEffect(() => {
    const loggedInUserData = localStorage.getItem("loggedInUser");
    if (loggedInUserData) {
      const parsedData = JSON.parse(loggedInUserData);
      setRestaurantId(parsedData.restaurantId);
    }
  }, []);

  useEffect(() => {
    // Set the selected dish based on selectedDishId
    if (formData.selectedDishId) {
      setSelectedDish(formData.selectedDishId);
    }
  }, [dishOptions, formData.selectedDishId]);

  const getLocalFormattedDate = (date: string | undefined) => {
    if (date) {
      var localDate = new Date(date);

      const year = localDate.getFullYear();
      const month = String(localDate.getMonth() + 1).padStart(2, "0"); // Adding 1 because getMonth returns 0-based index
      const day = String(localDate.getDate()).padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}`;
      return formattedDate;
    }

    return "";
  };

  const [isUploadModalOpen, setUploadModalOpen] = useState(false);

  const handleUploadshow = () => {
    // setPublishModalOpen(false);
    setUploadModalOpen(true);
    setShowRegisterModal(false);
  };
  const handleUploadClose = () => {
    setUploadModalOpen(false);
  };

  const [menuId, setMenuId] = useState("");
  const SetMenuID = (menuId: string) => {
    if (menuId !== null || 0) {
      handlePublishShow();
      setMenuId(menuId);
      console.log("Menu id");
      console.log(menuId);
    }
  };
  const handleUpdateChange = (
    selectedOption: { value: string; label: string } | null
  ) => {
    if (selectedOption) {
      const selectedValue = selectedOption.value;
      setSelectedDish(selectedValue);
      setFormData((prevFormData) => ({
        ...prevFormData,
        selectedDishId: selectedValue,
      }));
    }
  };

  const getAllActiveDishes = async () => {
    try {
      const response = await api.get(
        "/api/restaurant/dish/active?page=1&limit=100"
      );
      console.log("success response", response.data);
      const formattedOptions = response.data.data.dish.map((item: Item) => ({
        value: item._id,
        label: item.name,
        code: item.code,
        description: item.description,
      }));
      setDishOptions(formattedOptions);
    } catch (error) {
      console.error("Error fetching dish data:", error);
      message.error("Error fetching dish data!");
    }
  };

  const formatDateTime = (dateString) => {
    if (!dateString) {
      return ""; // Return empty string if the date is empty
    }
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return ""; // Return empty string if the date is invalid
    }
    return date.toISOString().slice(0, 16); // Format the date to "YYYY-MM-DDTHH:MM"
  };

  const [isPublishModalOpen, setPublishModalOpen] = useState(false);
  const [isUpdatePublishModalOpen, setUpdatePublishModalOpen] = useState(false);
  const [branchOptions, setBranchOptions] = useState<Option[]>([]);
  const handleUpdatePublishShow = () => {
    // if (!validate()) {
    //   return;
    // }
    setUpdatePublishModalOpen(true);
    setShowUpdateModal(false);
  };
  const handleUpdatePublishClose = () => {
    setUpdatePublishModalOpen(false);
  };
  const handlePublishShow = () => {
    setPublishModalOpen(true);
    setShowRegisterModal(false);
    setUploadModalOpen(false);
    setShowUpdateModal(false);
    setSelected([]);
  };
  const handlePublishClose = () => {
    setPublishModalOpen(false);
  };
  const handleBranchChange = (selected) => {
    // Update the selected state
    setSelected(selected);
  
    // Extract branch IDs from selected options
    const branchIds = selected.map(option => option.value);
  
    // Update the formData with the new branchIds
    setFormData(prevFormData => ({
      ...prevFormData,
      assignedBranch: [{ branchId: branchIds }]
    }));
  
    console.log("**FormData");
    console.log(formData);
  };
  
  useEffect(() => {
    if (restaurantId) {
      console.log("logged restaurant iddd ", restaurantId);
      getAllBranches();
    }
  }, [restaurantId]);

  const getAllBranches = async () => {
    try {
      const response = await api.get(
        "api/identity/restaurant-branch/?page=1&limit=100&restaurantId=" +
          restaurantId
      );
      console.log("success response", response.data);

      const formattedOptions = response.data.data.branch.map((item) => ({
        value: item._id,
        label: item.name,
        code: item.code,
      }));
      setBranchOptions(formattedOptions);
    } catch (error) {
      console.error("Error fetching dish data:", error);
      message.error("Error fetching dish data!");
    }
  };
  const submitPublish = async (event) => {
    event.preventDefault(); // Prevent the default form submission behavior

    const data = {
      assignedBranch: formData.assignedBranch.map((branch) => ({
        branchId: branch.branchId,
      })),
    };
    const branchId = data.assignedBranch[0].branchId;

    let finalArray = [];

    for (let i = 0; i < branchId.length; i++) {
      let obj = {
        branchId: branchId[i],
        startDate: formData.startDate,
        endDate: formData.endDate,
      };
      finalArray.push(obj);
    }
    try {
      const payload = {
        assignedBranch: finalArray,
        selectedDishId: formData.selectedDishId,
        price: formData.price,
      };

      const response = await api.put(
        `/api/restaurant/today-special/${menuId}`,
        payload
      );
      message.success("Menu published successfully!");
      handlePublishClose();
      handleUploadShow(menuId);
      dishListFetchData(currentPage);
      setFormData({
        selectedDishId: "",
        price: 0,
        startDate: "",
    endDate: "",
    assignedBranch: [
     
    ],
      });
    } catch (error) {
      console.error("Error publishing menu:", error);
      message.error("Error publishing menu!");
    }
  };
  const SetPayloaddata = (data: any) => {
    console.log(data);
    setFormData({
      
      price: data.price,
      selectedDishId: data.selectedDishId,
    
      startDate: "",
    endDate: "",
    assignedBranch: [
     
    ],
    });
  };
  const submitUpdatePublish = async (event) => {
    event.preventDefault();
    let finalArray = [];

    for (let i = 0; i < selected.length; i++) {
      let obj = {
        branchId: selected[i].value,
        startDate: formData.startDate,
        endDate: formData.endDate,
      };
      finalArray.push(obj);
    }
    try {
      const payload = {
        assignedBranch: finalArray,
        selectedDishId: formData.selectedDishId,
        price: formData.price
      };
      const response = await api.put(
        `/api/restaurant/today-special/${dishId}`,
        payload
      );
      message.success("Menu published successfully!");
      dishListFetchData(currentPage);
      handleUpdatePublishClose();
      setDishId(null);

      setFormData({
        
        selectedDishId: "",
        price: 0,
        startDate: "",
    endDate: "",
    assignedBranch: [
     
    ],
      });
    } catch (error) {
      console.error("Error publishing menu:", error);
      message.error("Error publishing menu!");
    }
  };
  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setFormData((prevFormData) => {
      const updatedBranches = prevFormData.assignedBranch.map((branch) => ({
        ...branch,
        [name]: value,
      }));

      return {
        ...prevFormData,
        [name]: value, // Update the top-level startDate or endDate
        assignedBranch: updatedBranches,
      };
    });
  };
  useEffect(() => {
    if (isPublishModalOpen) {
      const now = new Date(); // Get the current local time
      const in24Hours = new Date(now.getTime() + 24 * 60 * 60 * 1000); // Add 24 hours to the current time

      // Format both dates to "YYYY-MM-DDTHH:mm" using local time
      const formatDateToLocal = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        
        return `${year}-${month}-${day}T${hours}:${minutes}`;
      };

      const formattedNow = formatDateToLocal(now);
      const formattedIn24Hours = formatDateToLocal(in24Hours);

      // Update form data with startDate and endDate
      setFormData((prevFormData) => ({
        ...prevFormData,
        startDate: prevFormData.startDate || formattedNow, // Set startDate if it's not already set
        endDate: prevFormData.endDate || formattedIn24Hours, // Set endDate if it's not already set
      }));
    }
}, [isPublishModalOpen]);

  return (
    <div>
      <div>
        <div className={`body-area${showNav ? " body-pd" : ""}`}>
          <SideBarHeader showNav={showNav} setShowNav={setShowNav} />
          <div className={`l-navbar${showNav ? " showSideNav" : ""}`}>
            <nav className="sideNav">
              <SideMenu />
            </nav>
          </div>
          {loading ? (
            <Loader />
          ) : (
            <div className="pt-4 pb-4">
              <section className="main shadow-sm">
                <div className="row mb-4">
                  <div className="col">
                    <h4> Today's Special Dishes</h4>
                  </div>
                  <div className="col text-end">
                    <button
                      type="button"
                      className="btn addbtn"
                      onClick={handleRegisterShow}
                    >
                      <i className="bi bi-clipboard-plus"></i>
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <thead>
                        <tr className="table-active">
                          <th scope="col">#</th>
                          <th scope="col">NAME</th>
                          <th scope="col">PRICE ({defaultCurrency})</th>

                          {/* <th scope="col">START DATE</th>
                          <th scope="col">END DATE</th> */}

                          <th scope="col" style={{ padding: "0 40px" }}>
                            STATUS
                          </th>
                          <th scope="col">ACTION</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dishData.map((item, index) => (
                          <tr key={item._id}>
                            <td>{(currentPage - 1) * 10 + index + 1}</td>
                            <td>{item.dishDetail.name}</td>
                            <td>{item.price}</td>

                            {/* <td>{getLocalFormattedDate(item.startDate)}</td>
                            <td>{getLocalFormattedDate(item.endDate)}</td> */}

                            <td>
                              {item.status === "active" ? (
                                <span className="badge bg-success rounded-pill">
                                  Active
                                </span>
                              ) : (
                                <span className="badge bg-error rounded-pill"></span>
                              )}
                              {item.status === "suspended" ? (
                                <span className="badge bg-error rounded-pill">
                                  Suspended
                                </span>
                              ) : (
                                <span className="badge bg-error rounded-pill"></span>
                              )}
                              {item.status === "creating" ? (
                                <span className="badge bg-warning rounded-pill">
                                  Creating
                                </span>
                              ) : (
                                <span className="badge bg-error rounded-pill"></span>
                              )}
                              {item.status === "waiting_for_approval" ? (
                                <span className="badge bg-warning rounded-pill">
                                  waitingForApproval
                                </span>
                              ) : (
                                <span className="badge bg-error rounded-pill"></span>
                              )}
                              &nbsp;
                              <button
                                className="btn-action rounded-circle act-btn"
                                onClick={() => handleStatusUpdateShow(item._id)}
                              >
                                <i className="bi bi-pencil-square"></i>
                              </button>
                            </td>

                            <td className="p-1 d-flex">
                              <button
                                className="btn-action rounded-circle act-btn m-1"
                                onClick={() => {
                                  // viewDishDetails(item._id, "view");
                                  handleShow(item._id);
                                }}
                              >
                                <i className="bi bi-eye"></i>
                              </button>{" "}
                              &nbsp;
                              <button
                                className="btn-action rounded-circle act-btn m-1"
                                onClick={() => {
                                  // viewDishDetails(item._id, "edit");
                                  handleUpdateShow(item._id);
                                }}
                              >
                                <i className="bi bi-pencil-square"></i>
                              </button>
                              {item.status === 'active' ? (
                                <button
                                  className="btn-action rounded-circle act-btn m-1"
                                  onClick={() => {
                                    message.error("Cannot delete: today special is active.");
                                  }}
                                >
                                  <i className="bi bi-trash "></i>
                                </button>
                              ) : (
                                <button
                                className="btn-action rounded-circle act-btn m-1"
                                onClick={() => {
                                  // viewDishDetails(item._id, "delete");
                                  handleDeleteShow(item._id);
                                }}
                              >
                                <i className="bi bi-trash"></i>
                              </button>
                              )}
                              
                              {/* <button
                                className="btn-action rounded-circle act-btn m-1"
                                onClick={() => {
                                  // viewDishDetails(item._id, "delete");
                                  handleUploadShow(item._id);
                                }}
                              >
                                <i className="bi bi-upload"></i>
                              </button>
                              <button
                                className="btn-action rounded-circle act-btn m-1"
                                onClick={() => {
                                  // viewDishDetails(item._id, "delete");
                                  handleShowPublish(item._id);
                                }}
                              >
                                <i className="bi bi-box-arrow-up"></i>
                              </button> */}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </section>
            </div>
          )}
        </div>
        <div className=" d-flex  justify-content-end pe-md-5">
          {!loading && (
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              setCurrentPage={setCurrentPage}
            />
          )}
        </div>
      </div>
      {showModal && (
        <div
          className="modal"
          tabIndex={-1}
          style={{
            display: "block",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Todays Special Dishes Details
                </h1>

                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleClose}
                ></button>
              </div>
              <>
                <DishDetailView
                  dishDetailData={dishDetailData}
                  handleClose={handleClose}
                  restaurantId={restaurantId}
                  defaultCurrency={defaultCurrency}
                />
              </>
            </div>
          </div>
        </div>
      )}

      {showDeleteModal && (
        <div
          className="modal"
          tabIndex={-1}
          style={{
            display: "block",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Delete Today Special?
                </h1>

                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleDeleteClose}
                ></button>
              </div>
              <div className="delete-text">
                {" "}
                Are you sure you want to delete?
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={handleDeleteClose}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={handleDelete}
                  className="btn btn-primary"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showStatusUpdateModal && (
        <div
          className="modal"
          tabIndex={-1}
          style={{
            display: "block",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Update status?
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleStatusUpdateClose}
                ></button>
              </div>
              <div className="delete-text">
                <div className="mb-12 col-md-12">
                  <label className="form-label">
                    Select status <span className="text-danger"> *</span>
                  </label>
                  <div>
                    <select
                      className="form-control down-arrow"
                      value={dishStatus}
                      onChange={(e) => setDishStatus(e.target.value)}
                    >
                      <option value="" disabled>
                        Select Status
                      </option>
                      <option value="creating">Creating</option>

                      <option value="active">Active</option>

                      <option value="suspended">Suspended</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={handleStatusUpdateClose}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={updateStatus}
                  className="btn btn-primary"
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {showRegisterModal && (
        <div
          className="modal"
          tabIndex={-1}
          style={{
            display: "block",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Add Todays Special Dish
                </h1>

                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleRegisterClose}
                ></button>
              </div>
              <>
                <DishRegister
                  handleRegisterClose={handleRegisterClose}
                  SetMenuID={SetMenuID}
                  SetPayloaddata={SetPayloaddata}
                />
              </>
            </div>
          </div>
        </div>
      )}
      {showUpdateModal && (
        <div
          className="modal"
          tabIndex={-1}
          style={{
            display: "block",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          {loading && <Loader />}
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Update Today's Special Dish
                </h1>

                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleUpdateClose}
                ></button>
              </div>
              <form>
                <div className="modal-body">
                  <div className="row">
                    <div className="col">
                      <div className="mb-3">
                        <label className="form-label">
                          Select The Dish<span className="text-danger"> *</span>
                        </label>
                        <Select
                          options={dishOptions}
                          onChange={handleUpdateChange}
                          value={dishOptions.find(
                            (option) => option.value === selectedDish
                          )}
                          className="react-select"
                        />
                      </div>
                    </div>

                    <div className="col">
                      <div className="mb-3">
                        <label className="form-label">
                          Price Of The Dish ({defaultCurrency}){" "}
                          <span className="text-danger"> *</span>
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          name="price"
                          value={formData.price}
                          placeholder="Enter Price Of The Dish"
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={handleRegisterClose}
                  >
                    Close
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={handleUpdatePublishShow}
                  >
                    Save & Next <i className="bi bi-caret-right-fill"></i>
                  </button>
                  <ToastContainer />
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      {isUploadModalOpen && (
        <div
          className="modal"
          tabIndex={-1}
          style={{
            display: "block",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Upload Today's Special Images
                </h1>

                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleUploadClose}
                ></button>
              </div>
              <>
                <section className="main shadow-sm">
                  <>
                    <div className="row ">
                      <div className="col-lg-6 col-md-6 text-center">
                        <Upload {...props(keys.images)} method="PUT">
                          <Button
                            className="text-start"
                            style={{ width: "310px", height: "60px" }}
                            icon={<UploadOutlined />}
                            name={keys.images}
                          >
                            Dish image (PNG, JPG, JPEG)
                            <span className="text-danger"> *</span>
                          </Button>
                        </Upload>
                        <div className="validate" />
                      </div>
                      <div className="col-lg-6 col-md-6 text-center">
                        <Upload {...props(keys.videos)} method="PUT">
                          <Button
                            className="text-start"
                            style={{ width: "310px", height: "60px" }}
                            icon={<UploadOutlined />}
                            name={keys.videos}
                          >
                            Dish video
                          </Button>
                        </Upload>
                        <div className="validate" />
                      </div>
                      {/* <Button onClick={handleUpload}>Upload</Button> */}
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-primary"
                        data-bs-dismiss="modal"
                        // onClick={handlePublishShow}
                      >
                        <i className="bi bi-caret-left-fill"></i> Previous
                      </button>
                      <button
                        onClick={handleUploadClose}
                        type="submit"
                        className="btn btn-primary"
                      >
                        Upload
                      </button>
                      <ToastContainer />
                    </div>
                  </>
                </section>
              </>
            </div>
          </div>
        </div>
      )}
      {isPublishModalOpen && (
        <div
          className="modal"
          tabIndex={-1}
          style={{
            display: "block",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Publish Today's Special
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handlePublishClose}
                ></button>
              </div>
              <form>
                <div className="modal-body">
                  <div className="row">
                    <div className="col">
                      <table className="table table-bordered">
                        <tbody>
                          <tr>
                            <div className=" row">
                              <div className=" col-md-4 mb-3">
                                <label>
                                  Select Branch
                                  <span className="text-danger"> *</span>
                                </label>
                                <MultiSelect
                                  options={branchOptions}
                                  value={selected}
                                  onChange={handleBranchChange}
                                  labelledBy="Select Branch"
                                  className="scrollable-dropdown"
                                />
                              </div>
                              <div className=" col-md-4 mb-3">
                                <label>Start Date & Time</label>
                                <input
                                  type="datetime-local"
                                  className="form-control"
                                  name="startDate"
                                  value={formData.startDate}
                                  style={{ width: "200px" }}
                                  placeholder="Enter Start Date & Time"
                                  onChange={handleDateChange}
                                />
                              </div>
                              <div className=" col-md-4 mb-3">
                                <label>End Date & Time</label>
                                <input
                                  type="datetime-local"
                                  className="form-control"
                                  name="endDate"
                                  value={formData.endDate}
                                  style={{ width: "200px" }}
                                  placeholder="Enter End Date & Time"
                                  onChange={handleDateChange}
                                />
                              </div>
                            </div>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-primary"
                      data-bs-dismiss="modal"
                      onClick={handlePublishClose}
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={submitPublish}
                    >
                      Save & Next <i className="bi bi-caret-right-fill"></i>
                    </button>
                    <ToastContainer />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
       {isUpdatePublishModalOpen && (
        <div
          className="modal"
          tabIndex={-1}
          style={{
            display: "block",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Update Publish Today's Special
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleUpdatePublishClose}
                ></button>
              </div>
              <form>
                <div className="modal-body">
                  <div className="row">
                    <div className="col">
                      <table className="table table-bordered">
                        <tbody>
                          <tr>
                            <div className=" row">
                              <div className=" col-md-4 mb-3">
                                <label>
                                  Select Branch
                                  <span className="text-danger"> *</span>
                                </label>
                                <MultiSelect
                                  options={branchOptions}
                                  value={selected}
                                  onChange={handleBranchChange}
                                  labelledBy="Select Branch"
                                  className="scrollable-dropdown"
                                />
                              </div>
                              <div className=" col-md-4 mb-3">
                                <label>Start Date & Time</label>
                                <input
                                  type="datetime-local"
                                  className="form-control"
                                  name="startDate"
                                  value={formData.startDate}
                                  style={{ width: "200px" }}
                                  placeholder="Enter Start Date & Time"
                                  onChange={handleDateChange}
                                />
                              </div>
                              <div className=" col-md-4 mb-3">
                                <label>End Date & Time</label>
                                <input
                                  type="datetime-local"
                                  className="form-control"
                                  name="endDate"
                                  value={formData.endDate}
                                  style={{ width: "200px" }}
                                  placeholder="Enter End Date & Time"
                                  onChange={handleDateChange}
                                />
                              </div>
                            </div>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-primary"
                      data-bs-dismiss="modal"
                      onClick={() => handleUpdateShow(dishId)}
                    >
                      <i className="bi bi-caret-left-fill"></i> Previous
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={submitUpdatePublish}
                    >
                      Save & Next <i className="bi bi-caret-right-fill"></i>
                    </button>
                    <ToastContainer />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )} 
      <ToastContainer />
    </div>
  );
}

export default TodaySpecialDish;
