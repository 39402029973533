import React, { useEffect, useState } from "react"; 
import "./restaurantMenu.css";
import GLightbox from "glightbox";
import api from "../../../api";
import defaultImg from "./img/menu/no img.png";
import { Dish } from "./RestaurantBranchMenuDetailInterface";
import { message } from "antd";
import Loader from "../../Loader";

interface RestaurantMenuProps {
  data: {
    menuId: string;
    branchId: string;
    restaurantId: string;
    selectedCurrency: string;
  };
}

interface RestaurantBranchMenuDetailInterface {
  _id: string;
  isActive: boolean;
  name: string;
  description: string;
  category: category[];
  totalPrice: number;
  imageAttachement: any[];
}

interface category {
  _id: string;
  name: string;
  code: string;
  description: string;
  dish: Dish[];
}

export default function BuffetMenu({ data }: RestaurantMenuProps) {
  const [showViewBranchModal, setShowViewBranchModal] = useState(false);
  const [selectedCurrencyData, setSelectedCurrencyData] = useState<string>("");
  const [restaurantBrantMenuDetails, setRestaurantBrantMenuDetails] =
    useState<RestaurantBranchMenuDetailInterface | null>(null);
  const [loading, setLoading] = useState(true);

  // State to manage active tab, with a fallback to an empty string if no category exists
  const [activeTab, setActiveTab] = useState<string | null>(
    restaurantBrantMenuDetails?.category.length > 0 ? restaurantBrantMenuDetails?.category[0]._id : null
  );

  const handleTabChange = (categoryId: string) => {
    setActiveTab(categoryId);
  };

  const viewDetails = () => {
    setShowViewBranchModal(true);
  };

  useEffect(() => {
    console.log(
      "inside RestaurantMenu component menuId: " +
        data.menuId +
        " branchId: " +
        data.branchId +
        " restaurantId: " +
        data.restaurantId
    );
    setSelectedCurrencyData(data.selectedCurrency);
    if (data.menuId) {
      getRestaurantBranchMenuDetails();
    }
  }, [data.menuId, data.branchId, data.restaurantId]);

  const getRestaurantBranchMenuDetails = async () => {
    setLoading(true);
    try {
      const url =
        "/api/distributor/branch-menu/" +
        data.menuId +
        "?branchId=" +
        data.branchId +
        "&restaurantId=" +
        data.restaurantId +
        "&isPreview=true";
      const response = await api.get(url);
      console.log("success menu details response", response.data.data);
      setRestaurantBrantMenuDetails(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching dish data:", error);
      message.error(error.response?.data?.message || "Error fetching data.");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (
      restaurantBrantMenuDetails &&
      restaurantBrantMenuDetails.category.length > 0
    ) {
      handleTabChange(restaurantBrantMenuDetails.category[0]._id);
    }
  }, [restaurantBrantMenuDetails]);

  useEffect(() => {
    const lightbox = GLightbox({
      selector: ".glightbox", 
    });
    return () => {
      lightbox.destroy();
    };
  }, []);

  const handleViewBranchClose = () => {
    setShowViewBranchModal(false);
  };

  return (
    <>
      <section id="menu" className="menu section-menu">
        <div className="container" data-aos="fade-up">
          {loading ? (
            <Loader />
          ) : (
            <div className="row gy-4">
              <div className="">
                <div
                  className="custom-dish-card h-100"
                  style={{ height: "400px" }}
                  onClick={() => viewDetails()}
                >
                  <div className="cursor-pointer">
                    <img
                      src={
                        restaurantBrantMenuDetails?.imageAttachement?.[0]?.url ||
                        defaultImg
                      }
                      className="card-img"
                      alt={restaurantBrantMenuDetails?.name || "Default Alt Text"}
                      
                    />
                  </div>
                  <div className="custom-dish-card-body">
                    <h5 className="custom-dish-card-title dish-name cursor-pointer">
                      {restaurantBrantMenuDetails?.name}
                    </h5>
                    <p className="price">
                      <strong>
                        {selectedCurrencyData}{" "}
                        {restaurantBrantMenuDetails?.totalPrice ?? 0}
                      </strong>
                    </p>

                    <p className="custom-dish-card-text text-muted">
                      {restaurantBrantMenuDetails?.description.substring(0, 70)}...
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
          {showViewBranchModal && (
            <div
              className="modal fade show"
              tabIndex={-1}
              style={{
                display: "block",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              }}
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1 className="modal-title fs-5" id="exampleModalLabel">
                      Buffet Menu Details
                    </h1>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={handleViewBranchClose}
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div className="mb-3">
                      <table className="table table-bordered view-table infoTable">
                        <tbody>
                          <tr>
                            <th>MENU NAME</th>
                            <td>{restaurantBrantMenuDetails?.name}</td>
                            <th>Total Price ({selectedCurrencyData})</th>
                            <td>
                              {selectedCurrencyData}{" "}
                              {restaurantBrantMenuDetails?.totalPrice ?? 0}
                            </td>
                          </tr>
                          <tr>
                            <th>Buffet Image</th>
                            <td>
                              <img
                                src={
                                  restaurantBrantMenuDetails
                                    ?.imageAttachement[0].url
                                }
                                height={200}
                                width={200}
                                className="img-fluid"
                                alt="MenuDealz"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <ul
                        className="nav nav-tabs"
                        style={{ backgroundColor: "white" }}
                        data-aos="fade-up"
                        data-aos-delay={200}
                      >
                        {restaurantBrantMenuDetails?.category?.length > 0 &&
                          restaurantBrantMenuDetails?.category?.map((item, index) => (
                            <li key={index} className="nav-item">
                              <a
                                className={`nav-link ${
                                  activeTab === item._id ? "active" : ""
                                }`}
                                onClick={() => handleTabChange(item._id)}
                              >
                                <h4>{item.name}</h4>
                              </a>
                            </li>
                          ))}
                      </ul>
                      {restaurantBrantMenuDetails?.category?.length > 0 &&
                        restaurantBrantMenuDetails?.category?.map((item, index) => (
                          <div
                            key={index}
                            className={`tab-pane fade ${
                              activeTab === item._id ? "show active" : ""
                            }`}
                            id={item._id}
                          >
                            {activeTab === item._id && (
                              <div>
                                <h3>{item.name}</h3>
                                <table className="table table-bordered">
                                  <thead>
                                    <tr>
                                      <th>Dish Name</th>
                                      <th>Description</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {item.dish?.map((dish, dishIndex) => (
                                      <tr key={dishIndex}>
                                        <td>{dish.name}</td>
                                        <td>{dish.description}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            )}
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                      onClick={handleViewBranchClose}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
}
