import React, { useEffect, useState } from "react";
// import { RestaurantSection } from "../../../constants/constant";
// Using ESM specification
import "./restaurantMenu.css";
// Using a bundler like webpack
import GLightbox from "glightbox";
import { toast } from "react-toastify";
import api from "../../../api";
import defaultImg from "./img/menu/no img.png";
// import { useParams } from "react-router-dom";
import { message } from "antd";
import Loader from "../../Loader";
// Define the type for props
interface RestaurantMenuProps {
  data: {
    menuId: string;
    branchId: string;
    restaurantId: string;
    selectedCurrency: string;
  };
}
interface RestaurantBranchMenuDetailInterface {
  _id: string;
  name: string;
  isActive: boolean;
  description: string;
  totalPrice: number;
  imageAttachement: any[];
}
export default function EventMenu({ data }: RestaurantMenuProps) {
  const [showViewBranchModal, setShowViewBranchModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedCurrencyData, setSelectedCurrencyData] = useState(String);

  const [restaurantBrantMenuDetails, setRestaurantBrantMenuDetails] =
    useState<RestaurantBranchMenuDetailInterface>();

  // State to manage active tab
  // const [activeTab, setActiveTab] = useState(restaurantBrantMenuDetails?.category[0]._id);

  // Function to handle tab change
  // const handleTabChange = (categoryId: string) => {
  //   setActiveTab(categoryId);
  // };

  useEffect(() => {
    console.log(
      "inside RestaurantMenu component menuId: " +
        data.menuId +
        " branchId: " +
        data.branchId +
        " restaurantId: " +
        data.restaurantId
    );
    setSelectedCurrencyData(data.selectedCurrency)
    if (data.menuId) {
      getRestaurantBranchMenuDetails();
    }
  }, [data.menuId, data.branchId, data.restaurantId]);

  const getRestaurantBranchMenuDetails = async () => {
    setLoading(true);
    try {
      var url =
        "/api/distributor/branch-menu/" +
        data.menuId +
        "?branchId=" +
        data.branchId +
        "&restaurantId=" +
        data.restaurantId +
        "&isPreview=true";
      const response = await api.get(url);
      console.log("success menu details response", response.data.data);
      setRestaurantBrantMenuDetails(response.data.data);
      setLoading(false);
      // let currencyData = localStorage.getItem("currencyDetails");
      // setSelectedCurrencyData(currencyData);
      // console.log("currencyData" + currencyData);
    } catch (error) {
      console.error("Error fetching dish data:", error);
      message.error(error.response.data.message);
    }
  };
  // useEffect(() => {
  //   // Ensure that restaurantBrantMenuDetails has been updated before setting the active tab
  //   if (restaurantBrantMenuDetails && restaurantBrantMenuDetails.category.length > 0) {
  //     handleTabChange(restaurantBrantMenuDetails.category[0]._id);
  //   }
  // }, [restaurantBrantMenuDetails]);

  useEffect(() => {
    // Initialize GLightbox when the component mounts
    const lightbox = GLightbox({
      selector: ".glightbox", // selector for lightbox elements
    });

    // Optionally, you can clean up the lightbox when the component unmounts
    return () => {
      lightbox.destroy();
    };
  }, []); // empty dependency array ensures the effect runs only once

  const handleViewBranchClose = () => {
    setShowViewBranchModal(false);
  };

  return (
    <>
      {/* ======= Menu Section ======= */}
      <section id="menu" className="menu section-menu"> 
  <div className="container" data-aos="fade-up">
    {loading ? (
      <Loader />
    ) : (
      <div className="section-header responsive-menu">
        {/* Image */}
        <div className="image-container">
          <img
            src={restaurantBrantMenuDetails?.imageAttachement?.[0]?.url || defaultImg}
            className="menu-img1 menu-img img-fluid"
            alt={restaurantBrantMenuDetails?.name || "Default Alt Text"}
          />
        </div>

        {/* Content */}
        <div className="content-container">
          <h4 className="dish-name cursor-pointer">
            {restaurantBrantMenuDetails?.name}
          </h4>
          <div className="price">
            {selectedCurrencyData} {restaurantBrantMenuDetails?.totalPrice ?? 0}
          </div>
          <div>
            {restaurantBrantMenuDetails?.description?.substring(0, 70)}...
          </div>
        </div>
      </div>
    )}
  </div>
</section>


    </>
  );
}
